<template>
    <section class="sc-dashboard">
        <div class="container flex-wrapper">
            <main class="main-content layout-calc">

                <h5>Zaduzenje van pretplate 0,00 e</h5>
                <!-- Nav tabs -->
                <ul class="nav nav-tabs">
                    <li>
                        <a class="nav-link tab-link active" data-toggle="tab" href="#home">
                            {{ $t('pages.devices.title') }}
                        </a>
                    </li>
                </ul>

                <section class="page-status bills">
                    <div v-if="contracts.length > 0">
                        <ul class="list-group bills-listing">
                            <li class="list-group-item d-flex justify-content-between align-items-center bills-info"
                                v-for="(device, index) in contracts"
                                :key="index"
                            >
                                <div class="left-info-bill">
                                    <img src="../../assets/images/mtel-no-bills.png" alt="" class="icon-img device-img">
                                    <span>{{ device.name }}</span>
                                    <span>{{ $t('pages.devices.activeDate') }} {{ device.created_at }}</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div v-else>
                        <img src="../../assets/images/mtel-no-bills.png" alt="" class="icon-img">
                        <h1>{{ $t('pages.bills.noBills') }}</h1>
                    </div>
                </section>
            </main>
        </div>
    </section>
</template>
<script>
import Devices from './Devices';
export default Devices;
</script>

<style scoped>
.device-img {
    position: absolute;
    left: 3px;
    top: 20px;
    font-size: 10px;
    width: 30px;
    height: 30px !important;
}
</style>
