import api from '@/services/api';
import Package from './Package/Package.vue';

export default {
    data() {
        return {
            tariffs: [],
        };
    },

    components: {
        Package,
    },

    methods: {
        /**
         * Get all tariffs on component mounted
         */
        getTariffs() {
            this.$Progress.start();
            api.get('/api/tv-tariffs')
                .then((response) => {
                    this.tariffs = response.data.body.tariffs;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
        },
    },

    mounted() {
        this.getTariffs();
    },
};
