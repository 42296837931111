import * as types from '@/store/mutation-types';
import actions from '@/store/actions/userActions';
import { store } from '@/store/store';

/**
 * Define the module state
 *
 * @type {object}
 */
const moduleState = {
    token: null,
    userId: null,
    user: {
        account: {
            languageId: 1,
        },
        userData: {
            address: '',
            city: null,
        },
        logosoftId: '',
        contractId: '',
        subscriptionId: '',
    },
    error: null,
    message: null,
    isSmsCodeSent: false,
    isLoggedIn: false,
    phoneNumber: false,
    validation_errors: { // Represent errors bellow inputs in forms
        phone: [],
        firstName: [],
        lastName: [],
        pin: [],
        code: [],
        password: [],
        username: [],
    },
};

const mutations = {
    [types.GET_SMS_CODE](state, userData) {
        state.userId = userData.userId;
        state.isSmsCodeSent = userData.isSmsCodeSent;
        state.phoneNumber = userData.phoneNumber;
    },

    [types.STORE_USER](state, userData) {
        state.token = userData.token;
        state.isLoggedIn = true;
        store.dispatch('user/getUserData');
    },

    [types.CHECK_FIRST_CONTRACT_TYPE]() {
        store.dispatch('user/checkFirstContractType');
    },

    [types.CLEAR_AUTH_DATA](state) {
        state.token = null;
        state.userId = null;
        state.user = {
            account: {
                languageId: 1,
                smsNotification: 1,
            },
            userData: {
                address: '',
                city: null,
            },
            logosoftId: '',
            contractId: '',
            subscriptionId: '',
        };
        state.isLoggedIn = false;
        state.isSmsCodeSent = false;
    },

    [types.STORE_MAIN_USER_DATA](state, userData) {
        state.user = userData.data;
    },

    [types.SHOW_LOGIN_ERRORS](state, loginError) {
        state.validation_errors = loginError.body.validation_errors;
        state.error = loginError.body.message;
    },
};

/**
 * Define the module getters
 *
 * @type {object}
 */
const getters = {
    user: (state) => state,
};

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state: moduleState,
};
