import VoiceMail from '@/components/Administration/Forms/VoiceMail.vue';
import ChangeLanguage from '@/components/Administration/Forms/ChangeLanguage.vue';
import SmsNotifications from '@/components/Administration/Forms/SmsNotifications.vue';
import Gdpr from '@/components/Administration/Forms/Gdpr.vue';
import UserSettings from '@/components/Administration/Forms/UserSettings.vue';

export default {
    components: {
        Gdpr,
        VoiceMail,
        UserSettings,
        ChangeLanguage,
        SmsNotifications,
    },
};
