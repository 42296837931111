<template>
    <!-- Modal -->
    <div id="confirmation-modal">
        <div id="listing-modal-wrapper">
            <div id="logout-modal-wrapper">
                <div class="modal fade show"
                     tabindex="-1" role="dialog"
                     aria-labelledby="exampleModalCenterTitle"
                     aria-hidden="true"
                     style="display: block"
                >
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4>dopuni kredit</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
                                    <span class="close-span" aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="confirmation-modal-body modal-body">
                                <div v-html="$t('confirmationModal.description')" class="confirmation-modal-description"></div>
                                <div id="modal-buttons">
                                    <button type="button"
                                            class="btn btn-primary"
                                            @click="redirectAccepted"
                                    >
                                        {{ $t('logoutModal.buttons.yes') }}
                                    </button>
                                    <button type="button"
                                            class="btn btn-light"
                                            @click="close"
                                    >
                                        {{ $t('logoutModal.buttons.no') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InsufficientFoundModal from './InsufficientFoundModal';
export default InsufficientFoundModal;
</script>
