<template>
    <div class="custom-control custom-switch">
        <input type="checkbox"
               class="custom-control-input"
               :id="'gdpr'+index"
               name="example"
               :checked="gdpr.agree"
               v-model="gdpr.agree"
        >
        <label class="custom-control-label" :for="'gdpr'+index" v-html="gdpr.text"></label>
        <div v-if="index === 0">
            <div
                class="custom-control custom-switch"
                v-for="(gdprChild, index) in gdprsChilds"
                :key="index"
            >
                <input type="checkbox"
                       class="custom-control-input"
                       :id="'gdprChild'+index"
                       name="example"
                       :checked="gdprChild.agree"
                       v-model="gdprChild.agree"
                       @change="childHasChanged(gdprChild)"
                >
                <label
                    class="custom-control-label"
                    :for="'gdprChild'+index"
                >
                    {{ gdprChild.text }}
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import GdprInput from './GdprInput';
export default GdprInput;
</script>
