<template>
    <section class="sc-dashboard">
        <div class="container flex-wrapper">

            <main class="main-content layout-calc">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link tab-link active" data-toggle="tab" href="#recharge-credit">
                            {{ $t('pages.rechargeCredit.tab1.name') }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link tab-link" data-toggle="tab" href="#recharge-voucher">
                            {{ $t('pages.rechargeCredit.tab2.name') }}
                        </a>
                    </li>
                </ul>

                <!-- Tab panes -->
                <div class="tab-content">
                    <Credit />

                    <Voucher />
                </div>
            </main>

        </div>
    </section>
</template>

<script>
import RechargeCredit from '@/components/RechargeCredit/RechargeCredit';
export default RechargeCredit;
</script>
