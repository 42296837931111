<template>
    <section class="sc-dashboard">
        <div class="container flex-wrapper">
            <main class="main-content layout-calc">
                <!-- TARIFF ACCOUNT RESOURCES -->
                <div v-if="resources.tariffPlan.length">
                    <div class="progress-bar-chart-wrapper">
                        <!-- Nav tabs -->
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <h2>{{ tariffName.toUpperCase() }}</h2>
                            </li>
                        </ul>
                        <TariffPlan
                            v-for="(tariffAccount, index) in resources.tariffPlan"
                            :key="index"
                            :account="tariffAccount"
                        />
                    </div>
                </div>
                <!-- BUNDLE ACCOUNT RESOURCES -->
                <div v-if="resources.bundleAccount.length">
                    <div class="progress-bar-chart-wrapper">
                        <!-- Nav tabs -->
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <h2>{{ $t('pages.resources.bundleAccount') }}</h2>
                            </li>
                        </ul>
                        <BundleAccount
                            v-for="(bundleAccount, index) in resources.bundleAccount"
                            :key="index"
                            :account="bundleAccount"
                        />
                    </div>
                </div>
                <!-- LOYALTY ACCOUNT RESOURCES -->
                <div v-if="resources.loyalty && resources.loyalty.length">
                    <div class="progress-bar-chart-wrapper">
                        <!-- Nav tabs -->
                        <ul class="nav nav-tabs">
                            <li class="nav-item">
                                <h2>{{ $t('pages.resources.loyalty') }}</h2>
                            </li>
                        </ul>
                        <Loyalty
                            v-for="(loyaltyAccount, index) in resources.loyalty"
                            :key="index"
                            :account="loyaltyAccount"
                        />
                    </div>
                </div>
            </main>
        </div>
    </section>
</template>

<script>
import Resources from './Resources';
export default Resources;
</script>
