export default {
    methods: {
        agreePrivacy() {
            this.$emit('paymentAgree', true);
        },

        close() {
            this.$emit('close', true);
        },
    },
};
