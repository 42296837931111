import { mapActions, mapGetters } from 'vuex';
import api from '@/services/api';

export default {
    data() {
        return {
            phoneNumber: '',
            smsStatus: 0,
        };
    },

    computed: {
        ...mapGetters({
            administration: 'administration/administration',
            user: 'user/user',
            contract: 'contract/contract',
        }),
    },

    methods: {
        ...mapActions('administration', ['changeSmsNotificationsOnOff']),
        /**
         * Change sms notifications on/off
         */
        submitChangeSmsNotification() {
            this.$Progress.start();
            this.changeSmsNotificationsOnOff({
                pickedNumberSmsNotifications: this.phoneNumber,
                option: this.smsStatus,
            })
                .then(() => {
                    this.$toast.open(this.$i18n.t('messages.success'));
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
        },

        /**
         * Get notification state for chosen number
         */
        getSmsState() {
            api.get(`/api/mvno-notifications?phone=${this.phoneNumber}`)
                .then((response) => {
                    this.smsStatus = response.data.body.state;
                });
        },
    },

    /**
     * Listen for object changed for clicked contract
     */
    watch: {
        'contract.clickedContract.phoneNumber': {
            handler(phone) {
                this.phoneNumber = phone;
                this.getSmsState();
            },
            deep: true,
        },
    },

    mounted() {
        if (this.contract.clickedContract.phoneNumber) {
            this.phoneNumber = JSON.parse(JSON.stringify(this.contract.clickedContract.phoneNumber));
        }
    },
};
