<template>
    <section class="sc-dashboard">
        <div class="container flex-wrapper">
            <main class="main-content layout-calc"  id="bill-page">

                <h5 v-if="showBillsRemaining">{{ $t('pages.bills.remainingAmount') }} {{ billsRemaining.toFixed(2) }} €</h5>
                <!-- Nav tabs -->
                <ul class="nav nav-tabs">
                    <li>
                        <a class="nav-link tab-link active" data-toggle="tab" href="#home">
                            {{ $t('pages.bills.title') }}
                        </a>
                    </li>
                </ul>

                <div id="bill-details" v-if="bills.length > 0">
                    <ul>
                        <li v-for="(bill, index) in bills" :key="index">
                            <span class="icon-new-plati-racun"></span>
                            <div class="first-span">
                                <h4 class="phone-number-left">
                                    {{ $t('pages.bills.status') }}: {{ bill.status == 'Zahlen' ? 'Bezahlt' : bill.status }}
                                </h4>
                                <span class="badge badge-pill">{{ bill.total.value }} {{ bill.total.label }}</span>
                            </div>
                            <div class="second-span">
                                <small>{{ $t('pages.bills.date') }}: {{ bill.forDate }}{{ dotOnSerbian() }}</small>
                                <a href="#"
                                   class="badge badge-pill"
                                   v-if="!bill.isBillPayed"
                                   @click="sendBillIdAndOpenModalForm(bill.referenceNumber, bill.remaining)"
                                >{{ $t('pages.bills.payOnline') }}</a>
                                <a class="badge badge-pill"
                                   href="#"
                                   v-else-if="bill.hasBillPdf"
                                   @click="downloadBillPdf(bill.billId, bill.filename)"
                                >{{ $t('pages.bills.pdf') }}</a>
                                <a class="badge badge-pill"
                                   href="#"
                                   v-if="bill.noderef"
                                   @click="downloadBillPdf(bill.noderef, bill.filename)"
                                >{{ $t('pages.bills.listingPdf') }}</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="noBills" id="no-bills">
                    <img src="../assets/images/mtel-no-bills.png" alt="" class="icon-img">
                    <h1>{{ $t('pages.bills.noBills') }}</h1>
                </div>

                <PaymentModal v-if="showPaymentModal" @close="closeModal" :formActionUrl="formActionUrl"/>

                <div ref="paymentScriptWrapper"></div>
            </main>
        </div>
    </section>
</template>
<script>
import Bills from './Bills';
export default Bills;
</script>
