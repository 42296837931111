import * as types from '@/store/mutation-types';
import actions from '@/store/actions/notificationActions';

/**
 * Define the module state
 *
 * @type {object}
 */
const moduleState = {
    allNotifications: [
        {
            id: 2,
            publishingDate: '2020-12-13T09:00:00+00:00',
            read: true,
            shortDescription: '',
            text: '',
            title: '',
        },
    ],
    currentNotification: {},
    unreadNotifications: 0,
};

const mutations = {
    /**
     * Setting all notifications
     *
     * @param state
     * @param userData
     */
    [types.SET_NOTIFICATIONS](state, userData) {
        // Set number of unread notifications
        state.unreadNotifications = userData.notifications.filter((item) => !item.read).length;
        // Set all notifications
        state.allNotifications = userData.notifications;
    },

    /**
     * Set current notification in state
     * @param state
     * @param userData
     */
    [types.SET_CURRENT_NOTIFICATION](state, userData) {
        state.currentNotification = userData.notification;
    },

    /**
     * Set number of unread notifications
     * @param state
     * @param notification
     */
    [types.SET_NUMBER_UNREAD_NOTIFICATION](state, notification) {
        state.unreadNotifications = notification.number;
    },
};

const getters = {
    notification: (state) => state,
    unreadNotification: (state) => state.unreadNotifications,
};

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state: moduleState,
};
