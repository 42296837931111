import api from '@/services/api';
import GdprInput from './GdprInput/GdprInput.vue';
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            gdprs: [],
            gdprsChilds: [],
        };
    },

    components: {
        GdprInput,
    },

    computed: {
        ...mapGetters({
            user: 'user/user',
            contract: 'contract/contract',
        }),
    },

    methods: {
        /**
         * Get GDPR states on mounted
         */
        getGdprStates() {
            // This fixes sending api call before app is mounted on reload
            if (!this.contract.clickedContract.phoneNumber) {
                return;
            }
            this.$Progress.start();

            api.get('/api/gdpr')
                .then((response) => {
                    this.gdprs = response.data.body.gdprs;
                    this.gdprsChilds = response.data.body.gdprsChilds;

                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
        },
    },

    /**
     * Listen for object changed for clicked contract
     */
    watch: {
        'contract.clickedContract.phoneNumber': {
            handler() {
                this.getGdprStates();
            },
            deep: true,
        },
    },

    mounted() {
        this.getGdprStates();
    },
};
