<template>
  <form id="pin-form">
    <label class="landing-label">
      <span class="label-title">{{ $t('pages.login.labels.name') }}</span>
      <input type="text"
             v-model.trim="username"
             ref="firstNameInput"
      >
      <small v-if="user.validation_errors && user.validation_errors.first_name">
          {{ user.validation_errors.username[0] }}
      </small>
    </label>

    <label class="landing-label">
      <span class="label-title">{{ $t('pages.login.labels.password') }}</span>
      <input type="password"
             v-model.trim="password"
             @keydown.enter.prevent="loginViaPassword({ username, password })"
      >
      <small v-if="user.validation_errors && user.validation_errors.last_name">
          {{ user.validation_errors.password[0] }}
      </small>
      <small v-if="user.error">
          {{ user.error }}
      </small>
    </label>

    <fieldset class="buttons">
      <button type="button"
              class="main-btn submit-btn phone-number"
              @click="loginViaPassword({ username, password })"
      >
        <span class="spinner-label">{{ $t('pages.login.buttons.submit') }}</span>
      </button>
    </fieldset>
  </form>
</template>

<script>
import UsernameForm from './UsernameForm';
export default UsernameForm;
</script>
