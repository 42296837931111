import actions from '@/store/actions/administrationActions';
import * as types from '@/store/mutation-types';

/**
 * Define the module state
 *
 * @type {object}
 */
const moduleState = {
    voiceMailChecked: 1,
    voiceMailStates: ['ACTIVE', 'NO_LEAVE_MESSAGES', 'SUSPENDED'],
    smsNotificationChecked: 1,
};

const mutations = {
    [types.SET_VOICE_MAIL_OPTION_CHECKED](state, checkedNumber) {
        state.voiceMailChecked = checkedNumber;
    },
};

/**
 * Define the module getters
 *
 * @type {object}
 */
const getters = {
    administration: (state) => state,
};

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state: moduleState,
};
