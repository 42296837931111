<template>
    <section class="sc-dashboard">
        <div class="container flex-wrapper">

            <main class="main-content layout-calc">
                <h3 class="text-center" v-if="isSuccess">{{ $t('pages.thankYouPage.success') }}</h3>
                <h3 class="text-center" v-else>{{ $t('pages.thankYouPage.error') }}</h3>
            </main>

        </div>
    </section>
</template>

<script>
import PaymentThankYouPage from './PaymentThankYouPage';
export default PaymentThankYouPage;
</script>
