<template>
    <section class="sc-dashboard">
        <div class="container flex-wrapper">
            <main class="main-content layout-calc">
                    <h2 class="title">{{ notification.currentNotification.title }}</h2>
                    <p>{{ notification.currentNotification.publishingDate | date }}</p>
                    <hr>
                    <p v-html="notification.currentNotification.text"></p>
            </main>
        </div>
    </section>
</template>

<script>
import Notification from './Notification';
export default Notification;
</script>
