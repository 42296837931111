import api from '@/services/api';

export default {
    props: ['gdpr', 'index', 'gdprsChilds'],

    methods: {
        submitGdprChange(gdpr) {
            this.$Progress.start();
            const params = new URLSearchParams();

            params.append('id', gdpr.id);
            params.append('agree', gdpr.agree ? 1 : 0);

            api.post('/api/submit-gdpr', params)
                .then(() => {
                    this.$toast.open(this.$i18n.t('messages.success'));
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
        },

        /**
         * This will trigger when user click on child checkbox
         * @param childGdpr
         */
        childHasChanged(childGdpr) {
            this.submitGdprChange(childGdpr);
        },
    },

    watch: {
        gdpr: {
            handler(newGdpr) {
                this.submitGdprChange(newGdpr);
            },
            deep: true,
        },
    },
};
