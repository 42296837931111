import Vue from 'vue';

Vue.filter('dotToComma', (value) => {
    if (!value) {
        return '';
    }
    let newValue = String(value);

    return newValue.replace('.', ',');
});
