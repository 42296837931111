import FloatBox from '@/components/Partials/FloatBox/FloatBox.vue';
import { mapGetters } from 'vuex';

export default {
    props: ['isActiveNewsMenu', 'toggleNewsMenu'],

    components: {
        FloatBox,
    },

    computed: {
        ...mapGetters({
            notification: 'notification/notification',
        }),

        allNotifications() {
            return this.notification.allNotifications;
        },
    },
};
