import CreditCard from '@/components/Partials/CreditCard.vue';
import ExternalPaymentsCard from '@/components/Partials/ExternalPaymentsCard.vue';

export default {
    data() {
        return {
            creditCard: false,
            formActionUrl: `${window.location.origin}/transaction-completed-mobile`,
            srcScript: process.env.VUE_APP_PAYMENT_URL,
            checkoutId: '', // preuzeti od jelene
        };
    },

    components: {
        CreditCard,
        ExternalPaymentsCard,
    },

    methods: {
        activeCreditCard() {
            this.creditCard = !this.creditCard;
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.checkoutId = this.$route.query.checkoutId;

            const scriptEl = document.createElement('script');
            scriptEl.setAttribute('src',
                `${this.srcScript}${this.checkoutId}`);
            this.$refs.paymentScriptWrapper.appendChild(scriptEl);
            this.showPaymentModal = true;
        });
    },
};
