<template>
    <div class="modal-content">

        <div class="modal-body">
<!--            <button type="button"
                    class="btn btn-primary"
                    @click="activeCreditCard"
            >
                {{ creditCard ? 'Paypal sofort' : 'Credit card' }}
            </button>-->
            <ul class="nav nav-tabs ul-payment">
                <li class="nav-item nav-payment" @click.prevent="creditCard = false">
                    <a :class="{'nav-link tab-link': true, 'active': !creditCard}">
                        {{ $t('paypalSofort') }}
                    </a>
                </li>
                <li class="nav-item nav-payment" @click.prevent="creditCard = true">
                    <a :class="{'nav-link tab-link': true, 'active': creditCard}">
                        {{ $t('creditCard') }}
                    </a>
                </li>
            </ul>
            <section>
                <ExternalPaymentsCard
                    :formActionUrl="formActionUrl"
                    :active="!creditCard"
                ></ExternalPaymentsCard>
            </section>
            <section>
                <CreditCard
                    :formActionUrl="formActionUrl"
                    :active="creditCard"
                ></CreditCard>
            </section>
        </div>
        <div ref="paymentScriptWrapper">

        </div>

    </div>
</template>

<script>
import MobilePaymentView from './MobilePaymentView';
export default MobilePaymentView;
</script>

<style scoped>
button {
    display: block;
    margin: 20px auto;
}
.modal-content {
    background: transparent;
    border: 0;
}
.modal-body {
    max-width: 480px;
    margin: 10px auto;
}
</style>
