import api from '@/services/api';

export default {
    /**
     * Change language for given number
     */
    changeLanguage({ commit }, administrationData) {
        const params = new URLSearchParams();
        params.append('language', administrationData.pickedLanguage);

        api.post('/api/change-mvno-language', params)
            .then((response) => {
                console.log(response);
            });
    },

    /**
     * Change sms notification on or off
     *
     * @param commit
     * @param administrationData
     */
    changeSmsNotificationsOnOff({ commit }, administrationData) {
        const params = new URLSearchParams();
        params.append('sms', administrationData.option);
        params.append('phone', administrationData.pickedNumberSmsNotifications);

        return api.post('/api/mvno-notifications', params);
    },

    /**
     * Change voice mail notification on / on restricted / off
     *
     * @param commit
     * @param state
     * @param voiceMailData
     */
    changeVoiceMailNotifications({ state }, voiceMailData) {
        const params = new URLSearchParams();
        params.append('voice_mail_state', state.voiceMailStates[voiceMailData.voiceMailChecked]);
        params.append('phone', voiceMailData.phone);

        return api.post('/api/voice-mail-state', params);
    },

    /**
     * Update checked option for voice mail notifications
     *
     * @param commit
     * @param state
     */
    updateVoiceMailOptionChecked({ commit, state }) {
        api.get('/api/voice-mail-state')
            .then((response) => {
                commit('SET_VOICE_MAIL_OPTION_CHECKED', state.voiceMailStates.indexOf(response.data.body.state));
            });
    },
};
