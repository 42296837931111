<template>
    <section class="sc-dashboard">
        <div class="container flex-wrapper">
            <main class="main-content layout-calc">
                <h2 class="title">{{ $t('pages.tv.title') }}</h2>
                <div class="package-item max-width-500" v-if="Object.keys(tariff).length">
                    <h3 class="package-title">{{ tariff.name }}</h3>
                    <ul>
                        <li class="package-info"><p><span class="color-red">{{ tariff.exYuChannels }}</span> {{ $t('pages.tv.exCanal') }}</p></li>
                        <li class="package-info"><p><span class="color-red">{{ tariff.hdChannels }}</span> {{ $t('pages.tv.hdCanal') }}</p></li>
                        <li class="package-info"><p><span class="color-red">{{ tariff.channelsForAdults }}</span> {{ $t('pages.tv.adultCanal') }}</p></li>
                        <li class="package-info"><p><span class="color-red">{{ tariff.radioStations }}</span> {{ $t('pages.tv.radio') }}</p></li>
                        <li class="package-info"><p>
                            <span v-if="tariff.videoClubSeriesAndMovies" class="color-red icon icon-check"></span>
                            <span v-else class="color-red">X</span>
                            {{ $t('pages.tv.videoClub') }}</p>
                        </li>
                        <li class="package-info"><p>
                            <span v-if="tariff.watchBackTo7Days" class="color-red icon icon-check"></span>
                            <span v-else class="color-red">X</span>
                            {{ $t('pages.tv.watchBack') }}
                        </p></li>
                        <li class="package-info"><p><span class="color-red">{{ tariff.maxNumberSofDevices }}</span> {{ $t('pages.tv.maxDevices') }}</p></li>
                        <li class="package-info"><p><span class="color-red">{{ tariff.simultaneousWatching }}</span> {{ $t('pages.tv.onSameDeviceCount') }}</p></li>
                    </ul>
                </div>
            </main>
        </div>
    </section>
</template>

<script>
import Tv from './Tv';
export default Tv;
</script>
