import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { store } from '@/store/store';

const sr = require('./sr.json');
const de = require('./de.json');

Vue.use(VueI18n);
const i18n = new VueI18n({
    locale: store.state.selectedLocale,
    messages: {
        sr,
        de,
    },
});

if (module.hot) {
    module.hot.accept(['./de.json', './sr.json'], () => {
        i18n.setLocaleMessage('de', de);
        i18n.setLocaleMessage('sr', sr);
    });
}

export default i18n;
