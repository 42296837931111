<template>
    <section class="sc-dashboard">
        <div class="container flex-wrapper">

            <main class="main-content layout-calc">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link tab-link active" data-toggle="tab" href="#home">
                            {{ $t('pages.customerSupport.title') }}
                        </a>
                    </li>
                </ul>

                <!-- Tab panes -->
                <div class="tab-content">
                    <div id="home" class="container tab-pane tab-panel-500 active"><br>
                        <div class="form-group">
                            <label for="exampleFormControlSelect1">
                                {{ $t('pages.customerSupport.title1') }}
                            </label>
                            <select class="form-control max-width-500" id="exampleFormControlSelect1" v-model="selectedSupportType">
                                <option v-for="supportType in supportedTypesForThisContract" :key="supportType.id" :value="supportType.id">{{ supportType.name }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="select-contract">
                                {{ $t('pages.customerSupport.titleContract') }}
                            </label>
                            <select class="form-control max-width-500" id="select-contract" v-model="phoneNumber">
                                <option
                                    v-for="(contract, index) in contract.contracts"
                                    :value="contract.phoneNumber ? contract.phoneNumber : contract.logosoftContractId"
                                    :key="index"
                                >{{ contract.phoneNumber ? contract.phoneNumber : contract.logosoftContractId }}</option>
                            </select>
                        </div>
                        <div class="form-group">
                            <label for="supportMessage">
                                {{ $t('pages.customerSupport.title2') }}
                            </label>
                            <textarea class="form-control max-width-500"
                                      id="supportMessage"
                                      rows="5" v-model="message"
                                      :placeholder="$t('pages.customerSupport.description')"
                            ></textarea>
                        </div>
                        <div class="form-group">
                            <button class="red-button" @click="submitTicket">
                                {{ $t('pages.customerSupport.button') }}
                            </button>
                        </div>
                    </div>
                </div>
            </main>

        </div>
    </section>
</template>

<script>
import CustomerSupport from '@/components/CustomerSupport';
export default CustomerSupport;
</script>
