import { mapGetters } from 'vuex';
import api from '@/services/api';
import GdprInput from '@/components/Administration/Forms/GdprInput/GdprInput.vue';

export default {
    data() {
        return {
            gdprs: [],
        };
    },

    components: {
        GdprInput,
    },

    computed: {
        ...mapGetters({
            user: 'user/user',
            contract: 'contract/contract',
        }),
    },

    methods: {
        close() {
            this.$emit('closeGdprModal', true);
            const agree = this.gdprs.filter((gdpr) => gdpr.agree);

            if (!agree.length) {
                const params = new URLSearchParams();

                params.append('id', this.gdprs[0].id);
                params.append('agree', 0);

                api.post('/api/submit-gdpr', params)
                    .then(() => {
                        this.$toast.success(this.$i18n.t('messages.success'));
                    })
                    .catch(() => {
                        this.$toast.error(this.$i18n.t('messages.error'));
                    });
            }
        },

        /**
         * Get GDPR states on mounted
         */
        getGdprStates() {
            this.$Progress.start();

            api.get('/api/gdpr')
                .then((response) => {
                    this.gdprs = response.data.body.gdprs;

                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
        },
    },

    mounted() {
        this.getGdprStates();
    },
};
