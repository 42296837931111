<template>
    <!-- Modal -->
    <div id="logout-modal">
        <div id="listing-modal-wrapper">
            <div id="logout-modal-wrapper">
                <div class="modal fade show"
                     tabindex="-1" role="dialog"
                     aria-labelledby="exampleModalCenterTitle"
                     aria-hidden="true"
                     style="display: block"
                >
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4>{{ $t('logoutModal.title') }}</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
                                    <span class="close-span" aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <button type="button"
                                        class="btn btn-danger"
                                        @click="closeModalAndLogoutUser"
                                >
                                    {{ $t('logoutModal.buttons.yes') }}
                                </button>
                                <button type="button"
                                        class="btn btn-light"
                                        @click="close"
                                >
                                    {{ $t('logoutModal.buttons.no') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LogoutModal from './LogoutModal';
export default LogoutModal;
</script>
