<template>
    <div id="sms-notification-tab" class="container tab-pane fade"><br>
        <div class="form-group">
            <label for="chooseNumber2">{{ $t('pages.administration.tab2.title1') }}</label>
            <select class="form-control max-width-500"
                    id="chooseNumber2"
                    v-model="phoneNumber"
                    @change="getSmsState"
            >
                <option
                    v-for="(contract, index) in contract.contracts"
                    :value="contract.phoneNumber"
                    :key="index"
                >{{ contract.phoneNumber }}</option>
            </select>
        </div>
        <div class="form-group">
            <div class="form-check">
                <input class="form-check-input"
                       type="radio"
                       id="turnOnSms"
                       value="1"
                       v-model="smsStatus"
                       name="turnOnSmsNotification"
                >

                <label class="form-check-label" for="turnOnSms">
                    {{ $t('pages.administration.tab2.radioButton1') }}
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input"
                       type="radio"
                       id="turnOffSms"
                       value="0"
                       v-model="smsStatus"
                       name="turnOnSmsNotification"
                >

                <label class="form-check-label" for="turnOffSms">
                    {{ $t('pages.administration.tab2.radioButton2') }}
                </label>
            </div>
        </div>
        <div class="form-group">
            <button class="red-button" @click="submitChangeSmsNotification">
                {{ $t('pages.administration.tab2.button') }}
            </button>
        </div>
    </div>
</template>

<script>
import SmsNotifications from './SmsNotifications';
export default SmsNotifications;
</script>
