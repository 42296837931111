/**
 * Defines mutations used in stores
 */

export const SET_LANGUAGE = 'SET_LANGUAGE';

/**
 * user mutations
 */
export const GET_SMS_CODE = 'GET_SMS_CODE';
export const STORE_USER = 'STORE_USER';
export const CLEAR_AUTH_DATA = 'CLEAR_AUTH_DATA';
export const SHOW_LOGIN_ERRORS = 'SHOW_LOGIN_ERRORS';
export const STORE_MAIN_USER_DATA = 'STORE_MAIN_USER_DATA';
export const CHECK_FIRST_CONTRACT_TYPE = 'CHECK_FIRST_CONTRACT_TYPE';

/**
 * contracts mutations
 */
export const SET_USER_CONTRACTS = 'SET_USER_CONTRACTS';
export const SET_CLICKED_CONTRACT = 'SET_CLICKED_CONTRACT';
export const CLEAR_CONTRACT_DATA = 'CLEAR_CONTRACT_DATA';

/**
 * vuex mutation for Administration page
 */
export const SET_VOICE_MAIL_OPTION_CHECKED = 'SET_VOICE_MAIL_OPTION_CHECKED';

/**
 * vuex mutation for Notifications
 */
export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS';
export const SET_CURRENT_NOTIFICATION = 'SET_CURRENT_NOTIFICATION';
export const SET_NUMBER_UNREAD_NOTIFICATION = 'SET_NUMBER_UNREAD_NOTIFICATION';

/**
 * vuex mutation for Loader screen
 */
export const TURN_ON_PRELOADER_AXIOS_REQUEST_SCREEN = 'TURN_ON_PRELOADER_AXIOS_REQUEST_SCREEN';
export const TURN_OFF_PRELOADER_AXIOS_REQUEST_SCREEN = 'TURN_OFF_PRELOADER_AXIOS_REQUEST_SCREEN';

/**
 * vuex mutation for Listings
 */
export const SET_ALL_LISTINGS = 'SET_ALL_LISTINGS';
export const SET_CLICKED_MODAL_LISTING = 'SET_CLICKED_MODAL_LISTING';
export const SET_CLOSE_MODAL_ON_CLICK = 'SET_CLOSE_MODAL_ON_CLICK';

/**
 * Set resources
 * @type {string}
 */
export const SET_RESOURCES = 'SET_RESOURCES';
export const SET_GDPR = 'SET_GDPR';
