<template>

        <!-- Tab panes -->
        <div class="tab-content">
            <div class="container progress-bar active">
                <ul class="bar-chart-list">
                    <li class="bar-chart-item"
                    >
                        <span class="label">
                            {{ account.name }}
                            <i class="tooltip-icon"
                               @click="showDescription"
                               v-if="account.description && account.description.length > 2"
                            ></i>
                        </span>
                        <div class="down-text text-left tooltip-description" v-if="account.description && account.description.length > 2 && description">
                                <button type="button" class="close" @click="hideDescription">
                                    <span class="close-span" aria-hidden="true">&times;</span>
                                </button>
                            <span>
                                {{ account.description }}
                            </span>
                        </div>

                        <span class="upper-text text-right d-block value">
                            <strong>{{ account.balance }} / {{ account.initialBalance }} {{ account.unit }}</strong>
                        </span>
                        <div class="progress">
                            <div class="progress-bar bg-danger"
                                 :style="width"
                            ></div>
                        </div>
                        <span class="down-text text-right d-block duration">
                            {{ $t('pages.spentResources.tab1.titleBottomRight') }}
                            : {{ account.expiryDate | date }}
                        </span>
                    </li>
                </ul>
            </div>
        </div>

</template>

<script>
import BundleAccount from './BundleAccount';
export default BundleAccount;
</script>
