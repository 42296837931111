import FloatBox from '@/components/Partials/FloatBox/FloatBox.vue';
import BonusModal from '@/components/Partials/BonusModal/BonusModal.vue';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            openModal: false,
        };
    },

    components: {
        FloatBox,
        BonusModal,
    },

    computed: {
        ...mapGetters({
            user: 'user/user',
            contract: 'contract/contract',
            resources: 'resources',
            locale: 'locale',
        }),

        /**
         * Check if user is on support page, if yes, then dont show him contract balance
         * and expire contract date
         *
         * @return  {Boolean}
         */
        isOnSupportPage() {
            return this.$route.name === 'customerSupport';
        },

        /**
         * Get end date of contract and check if comma is on the end of string and remove it
         * @return {*}
         */
        contractEndDate() {
            return this.contract.clickedContract.bindingEndDate.replace(/,\s*$/, '');
        },

        /**
         * Return is contract end date in past or future
         * @return boolean
         */
        isContractEndDateInFutureDate() {
            const now = new Date();
            const contractEndDateParts = this.contract.clickedContract.bindingEndDate.split('.');
            const endDate = new Date(
                contractEndDateParts[2],
                contractEndDateParts[1],
                contractEndDateParts[0]
            );

            return endDate >= now;
        },
    },

    methods: {
        /**
         * Open bonus modal on click
         */
        openBonusModal() {
            this.openModal = true;
        },

        /**
         * Close bonus modal
         */
        closeBonusModal() {
            this.openModal = false;
        },

        /**
         * Return dot for serbian date
         * @return {string}
         */
        dotOnSerbian() {
            if (this.locale === 'de') {
                return '';
            }

            return '.';
        },
    },
};
