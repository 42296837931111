<template>
  <form id="sms-form">
    <label class="landing-label">
      <span class="label-title">{{ $t('pages.login.sendSmsCode') }}</span>
      <input type="text"
             inputmode="numeric"
             autocomplete="one-time-code"
             :placeholder="$t('pages.login.smsCodeHere')"
             v-model.trim="smsCode"
             ref="smsLoginInput"
             @keydown.enter.prevent="loginViaSms({ code: smsCode, userId: user.userId })"
      >
      <small v-if="user.validation_errors.code">{{ user.validation_errors.code[0] }}</small>
    </label>

    <fieldset class="buttons">
      <button type="button"
              class="main-btn submit-btn phone-number"
              @click="loginViaSms({ code: smsCode, userId: user.userId })"
      >
        <span class="spinner-label">{{ $t('pages.login.buttons.submit') }}</span>
      </button>
    </fieldset>
  </form>
</template>

<script>
import SmsForm from './SmsForm';
export default SmsForm;
</script>
