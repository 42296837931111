import PaymentModal from '@/components/Partials/PaymentModal.vue';
import api from '@/services/api';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            bills: [],
            showPaymentModal: false,
            srcScript: process.env.VUE_APP_PAYMENT_URL,
            formActionUrl: 'transaction-completed-bill-paying',
            billsRemaining: 0,
            showBillsRemaining: false,
            noBills: false,
        };
    },

    components: {
        PaymentModal,
    },

    computed: {
        ...mapGetters({
            locale: 'locale',
            contract: 'contract/contract',
        }),
    },

    watch: {
        /**
         * When language is changed, then call again api call with new locale
         */
        locale() {
            this.getBills();
        },
        /**
         * Listen for object changed for clicked contract
         */
        'contract.clickedContract.phoneNumber': {
            handler() {
                this.getBills(this.contract.clickedContract.isMobile && this.contract.clickedContract.contractType === 'Postpaid');
            },
            deep: true,
        },
    },

    methods: {
        /**
         * Get all bills on component mounted
         */
        getBills(isPostpaid = false) {
            this.$Progress.start();
            api.get('/api/bills')
                .then((response) => {
                    this.bills = response.data;
                    if (response.data.length < 1) {
                        this.noBills = true;
                    } else {
                        this.noBills = false;
                    }
                    if (this.bills.length) {
                        this.billsRemaining = this.bills.map((bill) => parseFloat(bill.remaining)).reduce((a, b) => a + b);
                    } else {
                        this.billsRemaining = 0;
                    }
                    if (isPostpaid) {
                        this.showBillsRemaining = true;
                    }
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
        },

        /**
         * Send bill ID to our server then
         * if success - show user payment form
         * @param referenceNumber
         * @param amount
         * @return void
         */
        sendBillIdAndOpenModalForm(referenceNumber, amount) {
            const params = new URLSearchParams();
            params.append('reference_number', referenceNumber);
            params.append('amount', amount);
            api.post('/api/pay-bill', params)
                .then((response) => {
                    if (response.data.code === 'PREPARE_TRANSACTION_REQUEST_SUCCESS') {
                        const scriptEl = document.createElement('script');
                        scriptEl.setAttribute('src',
                            `${this.srcScript}${response.data.body.checkoutId}`);
                        this.$refs.paymentScriptWrapper.appendChild(scriptEl);
                        this.showPaymentModal = true;
                    }
                }).catch(() => {
                    this.$Progress.finish();
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.showPaymentModal = false;
                });
        },

        /**
         * This method will send id of bill to backend and then download pdf
         *
         * @param id
         * @param filename
         */
        downloadBillPdf(id, filename) {
            const params = new URLSearchParams();
            params.append('id', id);
            api.post('/api/download-pdf', params, {
                headers: {
                    responseType: 'blob',
                },
            })
                .then((response) => {
                    const fileLink = document.createElement('a');
                    fileLink.href = `data:application/pdf;base64,${response.data}`;
                    fileLink.setAttribute('download', filename);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                }).catch(() => {
                    this.$Progress.finish();
                    this.$toast.error(this.$i18n.t('messages.error'));
                });
        },

        /**
         * Close payment modal
         */
        closeModal() {
            this.showPaymentModal = false;
        },

        /**
         * Return dot for serbian date
         * @return {string}
         */
        dotOnSerbian() {
            if (this.locale === 'de') {
                return '';
            }

            return '.';
        },
    },

    mounted() {
        if (this.contract.clickedContract.isMobile && this.contract.clickedContract.contractType === 'Postpaid') {
            this.getBills(true);
        } else {
            this.getBills();
        }
    },
};
