import Navigation from '@/components/Partials/Navigation.vue';
import Footer from '@/components/Partials/Footer.vue';
import Preloader from '@/components/Partials/Preloader.vue';
import LogoutModal from '@/components/Authentication/Logout/LogoutModal.vue';
import NavigationBlueHeader from '@/components/Partials/NavigationBlueHeader.vue';
import Gdpr from '@/components/Authentication/GdprModal/Gdpr.vue';
import 'vue-toast-notification/dist/theme-sugar.css';
import { mapGetters } from 'vuex';
import { store } from '@/store/store';
import localStorage from '@/components/Services/storage';

export default {
    data() {
        return {
            isLogoutButtonClicked: false,
        };
    },

    components: {
        NavigationBlueHeader,
        LogoutModal,
        Navigation,
        Preloader,
        Footer,
        Gdpr,
    },

    computed: {
        ...mapGetters(['activePreloaderAxiosScreen']),

        ...mapGetters({
            user: 'user/user',
            contract: 'contract/contract',
        }),
        /**
         * Check if user is on login page, if yes, then dont show him navigation component
         *
         * @return  {Boolean}
         */
        isOnLoginPage() {
            return this.$route.name === 'login';
        },

        /**
         * Check if user is on 404 page
         */
        isOnNotFoundPage() {
            return this.$route.name === 'notFound';
        },

        /**
         * Check if user is mobile payments page form mobile app
         */
        isOnMobilePaymentPage() {
            return this.$route.name === 'mobilePaymentView' || this.$route.name === 'paymentThankYouPageMobile';
        },

        /**
         * Check for gdpr to show to user or not
         * @return {boolean}
         */
        showGdprModal() {
            return store.state.showGdpr;
        },
    },

    methods: {
        closeLogoutModal() {
            this.isLogoutButtonClicked = false;
        },

        closeGdprModal() {
            store.state.showGdpr = false;
        },
    },

    /**
     * Listen for object changed for clicked contract
     */
    watch: {
        'contract.clickedContract.phoneNumber': {
            handler() {
                store.dispatch('setGdpr');
            },
            deep: true,
        },
    },

    mounted() {
        // Set app language if there is any in local storage
        const languageFromLocalStorage = localStorage.get('locale', true);
        if (languageFromLocalStorage) {
            this.$i18n.locale = languageFromLocalStorage;
            store.dispatch('trySetLanguageFromLocalStorage', { language: languageFromLocalStorage });
        }
    },
};
