import Vue from 'vue';
import Vuex from 'vuex';
import * as types from '@/store/mutation-types';

// Import vuex store modules
import user from '@/store/modules/user';
import administration from '@/store/modules/administration';
import notification from '@/store/modules/notification';
import listing from '@/store/modules/listing';
import contract from '@/store/modules/contract';
import localStorage from '@/components/Services/storage';
import api from '@/services/api';

Vue.use(Vuex);

/**
 * Export Vuex store with strict mode and
 * middleware depending on config.ENV.debug setting for current environment
 *
 * @type {Vuex}
 */
export const store = new Vuex.Store({
    modules: {
        user,
        listing,
        notification,
        administration,
        contract,
    },

    state: {
        selectedLocale: 'de',
        preloaderAxiosRequestScreen: false,
        resources: {},
        showGdpr: false,
    },

    getters: {
        activePreloaderAxiosScreen: (state) => state.preloaderAxiosRequestScreen,
        locale: (state) => state.selectedLocale,
        resources: (state) => state.resources,
        showGdpr: (state) => state.showGdpr,
    },

    mutations: {
        [types.TURN_ON_PRELOADER_AXIOS_REQUEST_SCREEN](state) {
            state.preloaderAxiosRequestScreen = true;
        },
        [types.TURN_OFF_PRELOADER_AXIOS_REQUEST_SCREEN](state) {
            state.preloaderAxiosRequestScreen = false;
        },
        [types.SET_LANGUAGE](state, language) {
            state.selectedLocale = language;
        },
        [types.SET_RESOURCES](state, resources) {
            state.resources = resources;
        },
        [types.SET_GDPR](state, boolean) {
            state.showGdpr = boolean;
        },
    },

    actions: {
        /**
         * This action is called from navigation i app and we need new set of notifications
         * for that language change
         *
         * @param commit
         * @param dispatch
         * @param language
         */
        changeLanguage({ commit, dispatch }, language) {
            dispatch('notification/getAllNotifications');
            commit('SET_LANGUAGE', language);
            localStorage.save('locale', language, true);
        },
        /**
         * This action is called only from login pages, no need for dispatching new notifications
         *
         * @param commit
         * @param dispatch
         * @param language
         */
        changeLanguageOnLoginPagesOnly({ commit }, language) {
            commit('SET_LANGUAGE', language);
            localStorage.save('locale', language, true);
        },
        /**
         * On reload app, try set language from local storage if there is one
         *
         * @param commit
         * @param languageData
         */
        trySetLanguageFromLocalStorage({ commit }, languageData) {
            commit('SET_LANGUAGE', languageData.language);
        },
        /**
         * Set resources (user balande, contract end date) (data in blue header)
         * @param commit
         * @param resourcesData
         */
        setResources({ commit }, resoruces = false) {
            if (resoruces) {
                commit('SET_RESOURCES', resoruces);
                return;
            }
            api.get('/api/resources')
                .then((response) => {
                    commit('SET_RESOURCES', response.data.body.user);
                });
        },
        /**
         * Set gdpr for contract
         * @param commit
         */
        setGdpr({ commit }) {
            api.get('/api/gdpr-status')
                .then((response) => {
                    commit('SET_GDPR', response.data.body.GDPRShow);
                });
        },
    },

    strict: false,
});
