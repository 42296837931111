import api from '@/services/api';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            tariff: {},
        };
    },

    computed: {
        ...mapGetters({
            contract: 'contract/contract',
        }),
    },

    methods: {
        /**
         * Get tariff for TV
         */
        getTariff() {
            this.$Progress.start();
            api.get('/api/tv-tariff')
                .then((response) => {
                    this.tariff = response.data.body.tariff;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
        },
    },

    mounted() {
        if (this.contract.clickedContract.subscriptionId) {
            this.getTariff();
        }
    },

    watch: {
        'contract.clickedContract.subscriptionId': {
            handler() {
                this.getTariff();
            },
            deep: true,
        },
    },
};
