import api from '@/services/api';

export default {
    data() {
        return {
            phoneNumber: '',
            voucher: '',
        };
    },

    methods: {
        /**
         * Recharge credit with voucher for given number
         *
         * @returns {boolean}
         */
        submitVoucher() {
            this.$Progress.start();
            const params = new URLSearchParams();
            params.append('phone', this.phoneNumber);
            params.append('voucher', this.voucher);

            api.post('/api/voucher-topup', params)
                .then(() => {
                    this.voucher = '';
                    this.$toast.open(this.$i18n.t('messages.success'));
                })
                .catch((error) => {
                    // Voucher error
                    if (error.response.data.body.message) {
                        this.$toast.error(this.$i18n.t('messages.error'));
                    }
                    // Phone error
                    else if (error.response.data.body.validation_errors) {
                        this.$toast.error(this.$i18n.t('messages.error'));
                    }
                    // Mvno error
                    else {
                        this.$toast.error(this.$i18n.t('messages.error'));
                    }
                });
        },
    },
};
