import Vue from 'vue';
import { format } from 'date-fns';
import i18n from '@/i18n';

Vue.filter('date', (value) => {
    if (!value) {
        return '';
    }

    return format(new Date(value), i18n.t('dateFormat'));
});
