<template>
    <div class="modal fade show"
         tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle"
         aria-hidden="true"
         style="display: block"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="icon icon-new-strelica-desno outgoing">
                          <span class="path1">
                              <span class="path2"></span>
                          </span>
                    </span>
                    <div class="title-info-type">
                        <h5 class="modal-title" id="exampleModalLongTitle">{{ listing.clickedListing.phoneNumber }}</h5>
                        <p>{{ listing.clickedListing.callStart | date }}</p>
                    </div>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
                        <span class="close-span" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="reset-list">
                        <li>
                            <span class="left-span-modal">{{ $t('pages.listing.modal.call.price') }}</span>
                            <span>{{ listing.clickedListing.price.label }} {{ listing.clickedListing.price.value }}</span>
                        </li>
                        <li>
                            <span class="left-span-modal">{{ $t('pages.listing.modal.call.started') }}</span>
                            <span>{{ listing.clickedListing.callStart | hoursMinutesSeconds }}</span>
                        </li>
                        <li>
                            <span class="left-span-modal">{{ $t('pages.listing.modal.call.duration') }}</span>
                            <span>{{ listing.clickedListing.usedUnitsLabel }}</span>
                        </li>
                        <li>
                            <span class="left-span-modal">{{ $t('pages.listing.modal.call.roaming') }}</span>
                            <span>{{ listing.clickedListing.roaming ?  $t('messages.yes') : $t('messages.no') }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PhoneModal from './PhoneModal';
export default PhoneModal;
</script>

<style>
span.icon-new-strelica-desno {
    transform: rotate(-45deg);
    font-size: 16px !important;
    color: #ed1c24 !important;
}
</style>
