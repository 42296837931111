import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            pickedNumberVoiceMail: 1,
            phoneNumber: '',
        };
    },

    computed: {
        ...mapGetters({
            administration: 'administration/administration',
            contract: 'contract/contract',
        }),

        checkedNumberOfVoiceMail() {
            return this.administration.voiceMailChecked;
        },
    },

    methods: {
        ...mapActions('administration', ['changeVoiceMailNotifications', 'updateVoiceMailOptionChecked']),
        /**
         * Change voice mail notifications on/on restricted/off
         */
        submitChangeVoiceMailNotifications() {
            this.$Progress.start();

            this.changeVoiceMailNotifications(
                {
                    voiceMailChecked: this.administration.voiceMailChecked,
                    phone: this.phoneNumber,
                },
            )
                .then(() => {
                    this.$toast.open(this.$i18n.t('messages.success'));
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                });

            this.$Progress.finish();
        },
    },

    mounted() {
        /**
         * Update voice mail options for radio button in form
         */
        this.updateVoiceMailOptionChecked();

        if (this.contract.clickedContract.phoneNumber) {
            this.phoneNumber = JSON.parse(JSON.stringify(this.contract.clickedContract.phoneNumber));
        }
    },

    /**
     * Listen for object changed for clicked contract
     */
    watch: {
        'contract.clickedContract.phoneNumber': {
            handler(phone) {
                this.phoneNumber = phone;
                this.updateVoiceMailOptionChecked();
            },
            deep: true,
        },
    },
};
