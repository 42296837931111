import axios from 'axios';
import { store } from '@/store/store';
import localStorage from '@/components/Services/storage';
import router from '@/router';

const instance = axios.create({
    baseURL: process.env.VUE_APP_URL,
});

// Before each ajax request activate preloader screen
instance.interceptors.request.use((configAxios) => {
    configAxios.headers.common.Authorization = `Bearer ${localStorage.get('token', true)}`;
    configAxios.headers.common.Locale = store.state.selectedLocale;
    configAxios.headers.common.SubscriptionId = store.state.contract.clickedContract.subscriptionId;
    configAxios.headers.common.LogosoftId = store.state.contract.clickedContract.logosoftId;
    configAxios.headers.common.ContractId = store.state.contract.clickedContract.contractId;

    store.commit('TURN_ON_PRELOADER_AXIOS_REQUEST_SCREEN');
    return configAxios;
});

// After each ajax response deactivate preloader screen
// If response fail, again deactivate preloader screen too
instance.interceptors.response.use((configAxios) => {
    store.commit('TURN_OFF_PRELOADER_AXIOS_REQUEST_SCREEN');
    return configAxios;
}, (error) => {
    store.commit('TURN_OFF_PRELOADER_AXIOS_REQUEST_SCREEN');
    // handle if token expired
    if (error.response.data.code === 'REQUEST_FAILED_INVALID_TOKEN') {
        localStorage.remove('token');
        router.push('/login');
    }
    return Promise.reject(error);
});

export default instance;
