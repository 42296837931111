import api from '@/services/api';
import { mapGetters } from 'vuex';
import router from "@/router";

export default {
    data() {
        return {
            contracts: [],
        };
    },

    computed: {
        ...mapGetters({
            contract: 'contract/contract',
        }),
    },

    methods: {
        getDevices() {
            api.get('/api/get-ppi')
                .then((response) => {
                    this.contracts = response.data.body.contracts;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
        },
    },

    /**
     * Listen for object changed for clicked contract
     */
    watch: {
        'contract.clickedContract.isMobile': {
            handler() {
                // Dont let others to see this page if contract is TV type
                if (this.contract.clickedContract.isMobile) {
                    router.push('/');
                }
            },
            deep: true,
        },
    },

    mounted() {
        this.getDevices();
    },
};
