import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            phoneNumber: '',
        };
    },

    computed: {
        ...mapGetters({
            user: 'user/user',
        }),
    },

    methods: {
        ...mapActions('user', ['attemptToGetSmsCodeViaMsisdn']),
    },

    mounted() {
        this.$refs.phoneNumberInput.focus();
    },
};
