import api from '@/services/api';

export default {
    /**
     * Get all notifications from database and set into state
     *
     * @param commit
     * @param payload
     */
    getAllNotifications({ commit }) {
        api.get('/api/notifications')
            .then((response) => {
                commit('SET_NOTIFICATIONS', {
                    notifications: response.data.body.notifications,
                });
            });
    },

    /**
     * Set all user notifications
     *
     * @param commit
     * @param id
     * @param payload
     */
    readNotificationAndGetAllNotificationsAndSetCurrentNotification({ commit }, id) {
        api.post(`/api/read-notifications/${id}`)
            .then((response) => {
                commit('SET_NOTIFICATIONS', {
                    notifications: response.data.body.notifications,
                });
                commit('SET_CURRENT_NOTIFICATION', {
                    notification: response.data.body.notifications.find((notification) => notification.id === Number(id)),
                });
            });
    },

    /**
     * Set all user notifications
     *
     * @param commit
     * @param id
     * @param payload
     */
    readNotification({ commit }, id) {
        api.post(`/api/read-notifications/${id}`)
            .then((response) => {
                commit('MARK_READ_NOTIFICATION_GET_ALL_NOTIFICATIONS_SET_CURRENT_NOTIFICATION', {
                    notifications: response.data.body.notifications,
                    notification: response.data.body.notifications.find((notification) => notification.id === Number(id)),
                });
            });
    },
};
