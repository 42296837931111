import api from '@/services/api';
import PaymentModal from '@/components/Partials/PaymentModal.vue';
import ConfirmationModal from '@/components/Partials/ConfirmationModal/ConfirmationModal.vue';
import { mapGetters } from 'vuex';

export default {

    data() {
        return {
            rechargeCreditAmountError: false,
            rechargeCreditAmount: '',
            showPaymentModal: false,
            srcScript: process.env.VUE_APP_PAYMENT_URL,
            isCreditButtonClicked: false,
            formActionUrl: `${window.location.origin}/transaction-completed`,
            phone: '',
        };
    },

    components: {
        PaymentModal,
        ConfirmationModal,
    },

    computed: {
        ...mapGetters({
            contract: 'contract/contract',
        }),
    },

    methods: {
        /**
         * Send amount to our server then
         * if success - show user payment form
         */
        sendAmountAndOpenModalForm() {
            this.isCreditButtonClicked = false;
            const params = new URLSearchParams();
            params.append('amount', this.rechargeCreditAmount);
            params.append('phone', this.phone);
            params.append('type', 'topup');
            params.append('origin', 'at');
            api.post('/api/prepare-transaction-request', params)
                .then((response) => {
                    if (response.data.code === 'PREPARE_TRANSACTION_REQUEST_SUCCESS') {
                        // redirect user to external payment page specified in response.data.body.redirectUrl
                        window.location.href = response?.data?.body?.redirectUrl;
                        //     this.showPaymentModal = true;
                        //     const scriptEl = document.createElement('script');
                        //     scriptEl.setAttribute('src',
                        //         `${this.srcScript}${response.data.body.checkoutId}`);
                        //     this.$refs.paymentScriptWrapper.appendChild(scriptEl);
                    }
                }).catch((error) => {
                    if (error.response.data.code === 'TOPUP_FAILED_NUMBER_NOT_PREPAID') {
                        this.$toast.error(this.$i18n.t('messages.numberPostpaid'));
                    } else {
                        this.$toast.error(this.$i18n.t('messages.error'));
                    }
                    this.$Progress.finish();
                    this.showPaymentModal = false;
                });
        },

        /**
         * Close payment modal
         */
        closeModal() {
            this.showPaymentModal = false;
        },

        /**
         * Open confirmation modal
         */
        openConfirmationModal() {
            if (this.rechargeCreditAmount < 10 || this.rechargeCreditAmount > 50) {
                this.rechargeCreditAmountError = true;
                return;
            }

            this.isCreditButtonClicked = true;
        },

        /**
         * Close confirmation modal
         */
        closeConfirmationModal() {
            this.isCreditButtonClicked = false;
        },
    },
};
