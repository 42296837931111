import InternetModal from '@/components/Listing/Modal/Partials/InternetModal.vue';
import PhoneModal from '@/components/Listing/Modal/Partials/PhoneModal.vue';
import SmsModal from '@/components/Listing/Modal/Partials/SmsModal.vue';
import InfiniteLoading from 'vue-infinite-loading';
import { mapActions, mapGetters } from 'vuex';
import api from '@/services/api';

export default {
    data() {
        return {
            selectedType: 'ALL',
            infiniteId: +new Date(), // this reset scrolling package onChange select option
        };
    },

    components: {
        SmsModal,
        PhoneModal,
        InternetModal,
        InfiniteLoading,
    },

    computed: {
        ...mapGetters({
            listing: 'listing/listing',
            lastSixMonth: 'listing/lastSixMonths',
            lastMonth: 'listing/lastMonth',
            contract: 'contract/contract',
            locale: 'locale',
        }),
    },

    methods: {
        ...mapActions('listing', ['getAllListings', 'showModal', 'closeModal']),

        monthName(value) {
            if (!value) {
                return '';
            }
            if (this.locale === 'de') {
                return value.replace('.', '');
            }

            return value;
        },

        /**
         * On change select button month
         * @param monthName
         */
        onChangeMonth(monthName) {
            const selectedMonth = this.lastSixMonth.find((month) => month.name === monthName.target.value);
            this.listing.listings = [];
            this.infiniteId += 1;
            this.updateListingsOnMonthOrTypeChange(
                selectedMonth.firstDay,
                selectedMonth.lastDay,
                this.selectedType,
            );
        },

        /**
         * On change select button TYPE
         * @param type
         */
        onChangeType(type) {
            const selectedMonth = this.lastSixMonth.find((month) => month.name === this.lastMonth.name);
            this.listing.listings = [];
            this.infiniteId += 1;
            this.updateListingsOnMonthOrTypeChange(
                selectedMonth.firstDay,
                selectedMonth.lastDay,
                type.target.value,
            );
        },

        /**
         * Call api call for new listings
         *
         * @param startDate
         * @param endDate
         * @param type
         */
        updateListingsOnMonthOrTypeChange(startDate, endDate, type) {
            this.getAllListings({
                startDate,
                endDate,
                type,
            });
        },

        /**
         * Package for infinite loader data (on scrolling)
         */
        infiniteHandler($state) {
            const paginator = this.listing.pagination;
            let page = paginator.page + 1;
            if (page > paginator.numberOfPages) {
                $state.complete();
                return;
            }

            const selectedMonth = this.lastSixMonth.find((month) => month.name === this.lastMonth.name);
            const startDate = selectedMonth.firstDay;
            const endDate = selectedMonth.lastDay;
            const type = this.selectedType;
            let params = '';

            if (type === 'ALL') {
                params = `startDate=${startDate}&endDate=${endDate}&page=${page}`;
            } else {
                params = `type=${type}&startDate=${startDate}&endDate=${endDate}&page=${page}`;
            }

            api.get(`/api/listing?${params}`)
                .then((response) => {
                    if (response.data.body.listing.length) {
                        this.listing.listings = this.listing.listings.concat(response.data.body.listing);
                        this.listing.pagination.page = response.data.body.pagination.page;
                        $state.loaded();
                    } else {
                        $state.complete();
                    }
                    page++;
                });
        },

        /**
         * Download pdf for chosen month listing
         * @param id
         * @param filename
         */
        downloadListingPdf(id, filename) {
            const params = new URLSearchParams();
            params.append('id', id);
            api.post('/api/download-pdf', params, {
                headers: {
                    responseType: 'blob',
                },
            })
                .then((response) => {
                    const fileLink = document.createElement('a');
                    fileLink.href = `data:application/pdf;base64,${response.data}`;
                    fileLink.setAttribute('download', filename);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                }).catch(() => {
                    this.$Progress.finish();
                    this.$toast.error(this.$i18n.t('messages.error'));
                });
        },
        /**
         * Set initial listings
         */
        initialListingCall() {
            const selectedMonth = this.lastSixMonth.find((month) => month.name === this.lastMonth.name);
            const startDate = selectedMonth.firstDay;
            const endDate = selectedMonth.lastDay;
            const type = this.selectedType;
            this.getAllListings({
                startDate,
                endDate,
                type,
            });
        },
    },

    watch: {
        /**
         * Listen for object changed for clicked contract
         */
        'contract.clickedContract.phoneNumber': {
            handler() {
                this.initialListingCall();
            },
            deep: true,
        },
    },

    mounted() {
        this.initialListingCall();
    },
};
