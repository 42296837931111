import { mapActions } from 'vuex';

export default {
    methods: {
        // Logout user(clear data from vuex and delete token from local storage)
        ...mapActions('user', ['logout']),
        closeModalAndLogoutUser() {
            this.$emit('close', true);
            this.logout();
        },

        close() {
            this.$emit('close', true);
        },
    },
};
