import api from '@/services/api';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            selectedSupportType: 1,
            phoneNumber: '',
            supportTypes: [],
            message: '',
        };
    },

    computed: {
        ...mapGetters({
            contract: 'contract/contract',
        }),

        /**
         * Filter support types
         * @return {*[]}
         */
        supportedTypesForThisContract() {
            if (this.contract.clickedContract.isMobile) {
                this.selectedSupportType = 2;
                return this.supportTypes.filter((support) => support.id === 2);
            }
            this.selectedSupportType = 1;
            return this.supportTypes.filter((support) => support.id === 1);
        },
    },

    methods: {
        /**
         * Submit form with user text and selected type of ticket
         */
        submitTicket() {
            this.$Progress.start();
            const params = new URLSearchParams();
            params.append('request_type', this.selectedSupportType);
            params.append('contractid', this.phoneNumber);
            params.append('text', this.message);

            api.post('/api/support-requests', params)
                .then(() => {
                    this.$toast.open(this.$i18n.t('messages.success'));
                    this.message = '';
                    this.$Progress.finish();
                })
                .catch(() => {
                    if (this.message.length < 10) {
                        this.$toast.error(this.$i18n.t('messages.characterLength'));
                    } else {
                        this.$toast.error(this.$i18n.t('messages.error'));
                    }
                    this.$Progress.finish();
                });
        },

        /**
         * Getting support types from database on mount component
         */
        getRequestSupportedTypes() {
            this.$Progress.start();
            api.get('/api/support-request-types')
                .then((response) => {
                    this.supportTypes = response.data.body.supportRequestTypes;
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
        },
    },

    /**
     * Listen for object changed for clicked contract
     */
    watch: {
        'contract.clickedContract.phoneNumber': {
            handler(phone) {
                this.phoneNumber = phone;
            },
            deep: true,
        },
    },

    mounted() {
        /**
         * Get all support types from database
         */
        this.getRequestSupportedTypes();

        if (this.contract.clickedContract.phoneNumber) {
            this.phoneNumber = JSON.parse(JSON.stringify(this.contract.clickedContract.phoneNumber));
        }
    },
};
