<template>
        <div class="container flex-wrapper">
            <div class="wrapper-centered">
                <h3 class="text-center" v-if="isSuccess">{{ $t('pages.thankYouPage.success') }}</h3>
                <h3 class="text-center" v-else>{{ $t('pages.thankYouPage.error') }}</h3>
            </div>
        </div>
</template>

<script>
import PaymentThankYouPageMobile from './PaymentThankYouPageMobile';
export default PaymentThankYouPageMobile;
</script>
