<template>
    <div>
        <NewsSideBar :isActiveNewsMenu="isActiveNewsMenu" :toggleNewsMenu="toggleNewsMenu"/>
        <div class="container container-header">
            <header class="main-header">
                <div class="logo">
                    <router-link :to="{name: 'resources'}" v-if="contract.clickedContract.isMobile">
                        <img src="../../assets/logo.png" alt="logo">
                    </router-link>
                    <router-link :to="{name: 'tv'}" v-else>
                        <img src="../../assets/logo.png" alt="logo">
                    </router-link>
                </div>

                <nav id="mainNav" :class="{'main-nav': true, 'open': isActiveHamburgerMenu}">
                    <ul class="reset-list">
                        <li class="nav-item" v-if="!contract.clickedContract.isMobile">
                            <router-link
                                :to="{name: 'tv'}"
                                class="nav-link">
                                {{ $t('pages.overview.name') }}
                            </router-link>
                        </li>
                        <li
                            class="nav-item"
                            v-if="(contract.clickedContract.contractType !== 'Postpaid' && contract.clickedContract.isMobile)"
                        >
                            <router-link
                                :to="{name: 'tariffs'}"
                                class="nav-link"
                            >
                                {{ $t('pages.tariffs.name') }}
                            </router-link>
                        </li>
                        <li
                            class="nav-item"
                            v-if="contract.clickedContract.contractType === 'Prepaid' && !contract.clickedContract.isMobile"
                        >
                            <router-link
                                :to="{name: 'tvTariffs'}"
                                class="nav-link"
                            >
                                {{ $t('pages.tariffs.name') }}
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="contract.clickedContract.isMobile">
                            <router-link
                                :to="{name: 'packages'}"
                                class="nav-link"
                            >
                                {{ $t('pages.additionalPackages.name') }}
                            </router-link>
                        </li>
                        <li class="nav-item"
                            v-if="contract.clickedContract.contractType === 'Postpaid'"
                        >
                            <router-link
                                :to="{name: 'bills'}"
                                class="nav-link"
                            >
                                {{ $t('pages.bills.name') }}
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="!contract.clickedContract.isMobile">
                            <router-link
                                :to="{name: 'devices'}"
                                class="nav-link"
                            >
                                {{ $t('pages.devices.name') }}
                            </router-link>
                        </li>
                        <li class="nav-item" v-if="contract.clickedContract.isMobile">
                            <router-link
                                :to="{name: 'administration'}"
                                class="nav-link"
                            >
                                {{ $t('pages.administration.name') }}
                            </router-link>
                        </li>
                        <li class="nav-item">
                            <router-link
                                :to="{name: 'customerSupport'}"
                                class="nav-link"
                            >
                                {{ $t('pages.customerSupport.name') }}
                            </router-link>
                        </li>
                    </ul>

                </nav>

                <nav class="right-info-nav">
                    <ul class="reset-list">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle language-dropdown-link" href="#" id="navbarDropdown" role="button"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{ locale === 'sr' ? 'SRB' : 'DE' }}
                            </a>
                            <div class="dropdown-menu language-dropdown" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item select-language"
                                   @click="changeLanguageApp('de')"
                                >
                                    DE
                                </a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item select-language"
                                   @click="changeLanguageApp('sr')"
                                >
                                    SRB
                                </a>
                            </div>
                        </li>
                        <li>
                            <span class="icon icon-new-notifications" @click="toggleNewsMenu"></span>
                            <span v-if="unreadNotification > 0" class="unread-notifications-number">{{ unreadNotification }}</span>
                        </li>
                        <li class="nav-item dropdown" >
                            <a class="name-wrap-link" href="#" id="profileDropdown" role="button"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="name-wrap d-none d-sm-block">{{ user.user.fullName }} </span> <span class="icon icon-new-user"></span>
                            </a>
                            <div class="dropdown-menu language-dropdown" aria-labelledby="profileDropdown">
                                <div class="user-info">
                                    <p class="label">{{ $t('address') }}</p>
                                    <p class="value">{{ user.user.address }}</p>
                                </div>
                                <div class="user-info">
                                    <p class="label">{{ $t('place') }}</p>
                                    <p class="value">{{ user.user.city }}</p>
                                </div>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item select-language" href="#" @click="logOutUser">{{ $t('logout') }}</a>
                            </div>
                        </li>
                        <li v-if="!auth">
                            <router-link :to="{name: 'login'}">Login</router-link>
                        </li>
                        <li class="toggle-wrap">
                            <button type="button" class="hamburger-menu-btn" @click='toggleNavHamburger'>
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </li>
                    </ul>
                </nav>
            </header>
        </div>

    </div>
</template>

<script>
import Navigation from './Navigation';
export default Navigation;
</script>
