<template>
    <header class="blue-header" id="blue-header">
        <div class="container flex-wrapper">
            <FloatBox/>

            <ul class="main-list layout-calc" v-if="!isOnSupportPage">
                <li v-if="(contract.clickedContract.contractType === 'Postpaid' || contract.clickedContract.contractType === 'Business') && resources.balance">
                    {{ $t('blueHeader.amountPostpaid') }}: <span v-if="resources.balance">{{ resources.balance.value }} €</span>
                </li>
                <li v-else-if="resources.balance">
                    {{ $t('blueHeader.amount') }}: <span v-if="resources.balance">{{ resources.balance.value }} €</span>
                </li>
                <li v-if="contract.clickedContract.bindingEndDate">
                    <span v-if="isContractEndDateInFutureDate">
                        {{ $t('blueHeader.endAt') }}: <span>{{ contractEndDate }}{{ dotOnSerbian() }}</span>
                    </span>
                    <span v-else>
                        {{ $t('blueHeader.endedAt') }}: <span>{{ contractEndDate }}{{ dotOnSerbian() }}</span>
                    </span>
                </li>
                <li
                    class="bonus-link"
                    v-if="resources && parseInt(resources.bonus) > 0"
                    @click="openBonusModal"
                >
                    {{ $t('blueHeader.bonus') }}: <span>{{ resources.bonus.value }} €</span>
                </li>
            </ul>
            <ul class="main-list layout-calc" v-else>
                <li>
                    Email: <span>{{ user.user.email }}</span>
                </li>
            </ul>
        </div>

        <BonusModal
            v-if="openModal"
            @close="closeBonusModal"
        />
    </header>
</template>

<script>
import NavigationBlueHeader from './NavigationBlueHeader';
export default NavigationBlueHeader;
</script>
