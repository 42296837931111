<template>
    <div id="voice-mail-tab" class="container tab-pane fade"><br>
        <div class="form-group">
            <label for="chooseNumber3">{{ $t('pages.administration.tab3.title1') }}</label>
            <select class="form-control max-width-500" id="chooseNumber3" v-model="phoneNumber">
                <option
                    v-for="(contract, index) in contract.contracts"
                    :value="contract.phoneNumber"
                    :key="index"
                >{{ contract.phoneNumber }}</option>
            </select>
        </div>
        <div class="form-group">
            <div class="form-check">
                <input class="form-check-input"
                       type="radio"
                       id="turnOnVoice"
                       v-model="administration.voiceMailChecked"
                       name="voice-mail-radio-option"
                       value="0">
                <label class="form-check-label" for="turnOnVoice">
                    {{ $t('pages.administration.tab3.radioButton1') }}
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input"
                       type="radio"
                       id="turnOnVoiceRestricted"
                       v-model="administration.voiceMailChecked"
                       name="voice-mail-radio-option"
                       value="1">
                <label class="form-check-label" for="turnOnVoiceRestricted">
                    {{ $t('pages.administration.tab3.radioButton2') }}
                </label>
            </div>
            <div class="form-check">
                <input class="form-check-input"
                       type="radio"
                       id="turnOffVoice"
                       v-model="administration.voiceMailChecked"
                       :checked="checkedNumberOfVoiceMail === 2 ? 'checked' : ''"
                       name="voice-mail-radio-option"
                       value="2">
                <label class="form-check-label" for="turnOffVoice">
                    {{ $t('pages.administration.tab3.radioButton3') }}
                </label>
            </div>
        </div>
        <div class="form-group">
            <button class="red-button"
                    @click="submitChangeVoiceMailNotifications"
            >
                {{ $t('pages.administration.tab3.button') }}
            </button>
        </div>
    </div>
</template>

<script>
import VoiceMail from './VoiceMail';
export default VoiceMail;
</script>
