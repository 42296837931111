<template>
    <div id="gdpr-tab" class="container tab-pane"><br>
        <p>{{ $t('pages.administration.tab4.title1') }}</p>
        <div class="form-group"
             v-for="(gdpr, index) in gdprs"
             :key="index"
        >
            <GdprInput :index="index" :gdpr="gdpr" :gdprsChilds="gdprsChilds"/>
        </div>
    </div>
</template>

<script>
import Gdpr from './Gdpr';
export default Gdpr;
</script>
