<template>
    <div :style="cssProps">
        <form :action="formActionUrl"
              class="paymentWidgets"
              data-brands="PAYPAL"
        ></form>
        <form :action="formActionUrl"
              class="paymentWidgets"
              data-brands="SOFORTUEBERWEISUNG"
        ></form>
    </div>
</template>

<script>
import ExternalPaymentsCard from './ExternalPaymentsCard';
export default ExternalPaymentsCard;
</script>

<style>
div.wpwl-container-virtualAccount-PAYPAL, div.wpwl-container-onlineTransfer-SOFORTUEBERWEISUNG {
    display: var(--display) !important;
}
</style>
