<template>
    <div id="app" class="d-flex flex-column min-vh-100">
        <!-- set progressbar - horizontal line -->
        <vue-progress-bar></vue-progress-bar>
        <!-- set preloader axios request - black opacity screen -->
        <Preloader v-if="activePreloaderAxiosScreen"/>

        <!-- when user click on logout button - show him modal confirmation -->
        <logout-modal v-if="isLogoutButtonClicked" @close="closeLogoutModal"/>

        <!-- show user gdpr modal -->
        <Gdpr v-if="showGdprModal" @closeGdprModal="closeGdprModal"/>

        <div class="wrapper" id="wrapper">
            <Navigation v-if="!isOnLoginPage && !isOnMobilePaymentPage" @logoutClicked="isLogoutButtonClicked = true"/>
            <NavigationBlueHeader v-if="!isOnLoginPage && !isOnNotFoundPage && !isOnMobilePaymentPage"/>

            <router-view/>
        </div>
        <Footer v-if="!isOnLoginPage && !isOnMobilePaymentPage"/>
    </div>
</template>

<script>
import '@/assets/styles/main.scss';

import App from './App';
export default App;
</script>
