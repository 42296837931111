import Vue from 'vue';
import i18n from '@/i18n';

Vue.filter('contractTypeChangeLanguage', (value) => {
    if (!value) {
        return '';
    }

    if (value === 'Postpaid') {
        return i18n.t('floatBox.postpaid');
    }
    if (value === 'Prepaid') {
        return i18n.t('floatBox.prepaid');
    }

    return value;
});
