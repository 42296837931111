<template>
    <section class="sc-dashboard">
        <div class="container flex-wrapper">
            <main class="main-content layout-calc">
                <div id="not-found-wrapper">
                    <h2 class="title">{{ $t('pages.notFound.title') }}</h2>
                    <img :src="notFoundImage" class="icon-img">
                    <router-link :to="{path: '/'}" class="btn btn-primary">{{ $t('pages.notFound.button') }}</router-link>
                </div>
            </main>
        </div>
    </section>
</template>

<script>
import NotFound from './NotFound';
export default NotFound;
</script>
