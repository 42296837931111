<template>
    <div id="change-language-tab" class="container tab-pane active"><br>
        <div class="form-group">
            <label for="chooseNumber">{{ $t('pages.administration.tab1.title1') }}</label>
            <select class="form-control max-width-500" id="chooseNumber" v-model="phoneNumber">
                <option
                    v-for="(contract, index) in contract.contracts"
                    :value="contract.phoneNumber"
                    :key="index"
                >{{ contract.phoneNumber }}</option>
            </select>
        </div>
        <div class="form-group">
            <label for="chooseLanguage">{{ $t('pages.administration.tab1.title2') }}</label>
            <select class="form-control max-width-500"
                    id="chooseLanguage"
                    v-model="languageId">
                <option value="1"
                >{{ serbianLanguageTitle }}</option>
                <option value="2"
                >{{ germanyLanguageTitle }}</option>
            </select>
        </div>
        <div class="form-group">
            <button class="red-button" @click="submitChangeLanguage">
                {{ $t('pages.administration.tab1.button') }}
            </button>
        </div>
    </div>
</template>

<script>
import ChangeLanguage from './ChangeLanguage';
export default ChangeLanguage;
</script>
