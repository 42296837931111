<template>
    <span>
        <div class="custom-collapse-element">
            <div id="accordion">
                <div class="card">
                  <div class="card-header" id="headingMain">
                      <h3 class="sidebar-title"
                          data-toggle="collapse"
                          data-target="#collapseMain"
                          aria-expanded="true"
                          aria-controls="collapseMain"
                          v-if="contractModule.clickedContract.isMobile"
                      >
                        {{ $t('floatBox.titleMobile') }}
                      </h3>
                      <h3 class="sidebar-title"
                          data-toggle="collapse"
                          data-target="#collapseMain"
                          aria-expanded="true"
                          aria-controls="collapseMain"
                          v-else
                      >
                        {{ $t('floatBox.titleTv') }}
                      </h3>
                  </div>

                  <div id="collapseMain"
                       :class="{'collapse': true, 'show': screenWidth > 1024}"
                       aria-labelledby="headingMain"
                       data-parent="#accordion"
                       ref="floatBoxMenu"
                  >
                    <div class="card-body">
                        <div id="accordion2">
                            <div class="card" v-for="(contract, index) in contractModule.contracts" :key="index">
                                <div :id="'heading' + index">
                                    <ul :class="{
                                        'sidebar-list-phone-numbers': true,
                                        'active': contract.contractId === contractModule.clickedContract.contractId
                                    }"
                                        @click="setClickedContract(contract)"
                                    >
                                        <li class="phone-number"
                                            data-toggle="collapse"
                                            :data-target="'#collapse' + index"
                                            aria-expanded="true"
                                            :aria-controls="'collapse' + index"
                                            v-if="contract.isMobile"
                                        >
                                            <span class="icon-new-uredjaji"></span>
                                            <p class="sidebar-number">{{ contract.phoneNumber }}</p>
                                            <span class="number-type">{{ contract.contractType | contractTypeChangeLanguage }}</span>
                                        </li>
                                        <li class="phone-number"
                                            data-toggle="collapse"
                                            :data-target="'#collapse' + index"
                                            aria-expanded="true"
                                            :aria-controls="'collapse' + index"
                                            v-else
                                        >
                                            <span class="icon-new-tv"></span>
                                            <p class="sidebar-number">{{ contract.tvTariff }}</p>
                                            <span class="number-type">{{ contract.contractType | contractTypeChangeLanguage }}</span>
                                        </li>
                                    </ul>
                                </div>

                                <div :id="'collapse' + index"
                                     :class="{'collapse': true, 'show': index === 0}"
                                     :aria-labelledby="'heading' + index"
                                     data-parent="#accordion2"
                                     v-if="contract.isMobile"
                                >
                                    <div class="card-body">
                                        <ul class="sidebar-list-link">
                                            <li>
                                                <router-link :to="{ name: 'listing'}">{{ $t('pages.phoneListing.name') }}</router-link>
                                            </li>
                                            <li>
                                                <router-link :to="{ name: 'rechargeCredit'}">{{ $t('pages.rechargeCredit.name') }}</router-link>
                                            </li>
                                            <li>
                                                <router-link :to="{ name: 'resources'}">{{ $t('myTariff') }}</router-link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
import FloatBox from './FloatBox';
export default FloatBox;
</script>

<style lang="scss">
    #headingMain {
        padding: 20px 0 0 0;
    }
    h3 {
        font-size: 20px;
        margin: 0;
        text-align: center;
        cursor: pointer;
    }

</style>
