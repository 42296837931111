<template>
    <div class="modal fade show"
         tabindex="-1" role="dialog"
         aria-labelledby="exampleModalCenterTitle"
         aria-hidden="true"
         style="display: block"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <span class="icon icon-new-mobilni-internet"></span>
                    <div class="title-info-type">
                        <h5 class="modal-title" id="exampleModalLongTitle">{{ $t('pages.listing.types.data') }}</h5>
                        <p>{{ listing.clickedListing.callStart | date }}</p>
                    </div>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
                        <span class="close-span" aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="reset-list">
                        <li>
                            <span class="left-span-modal">{{ $t('pages.listing.modal.data.price') }}</span>
                            <span>{{ listing.clickedListing.price.label }} {{ listing.clickedListing.price.value }}</span>
                        </li>
                        <li>
                            <span class="left-span-modal">{{ $t('pages.listing.modal.data.started') }}</span>
                            <span>{{ listing.clickedListing.callStart | hoursMinutesSeconds }}</span>
                        </li>
                        <li>
                            <span class="left-span-modal">{{ $t('pages.listing.modal.data.usage') }}</span>
                            <span>{{ listing.clickedListing.usedUnitsLabel }}</span>
                        </li>
                        <li>
                            <span class="left-span-modal">{{ $t('pages.listing.modal.data.spent') }}</span>
                            <span>{{ listing.clickedListing.usedUnits }}</span>
                        </li>
                        <li>
                            <span class="left-span-modal">{{ $t('pages.listing.modal.data.unit') }}</span>
                            <span>{{ listing.clickedListing.unit }}</span>
                        </li>
                        <li>
                            <span class="left-span-modal">{{ $t('pages.listing.modal.data.roaming') }}</span>
                            <span>{{ listing.clickedListing.roaming ?  $t('messages.yes') : $t('messages.no') }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InternetModal from './InternetModal';
export default InternetModal;
</script>
