export default {
    locale: 'sr',
    nProgressOptions: {
        color: '#002e56',
        failedColor: '#874b4b',
        thickness: '5px',
        transition: {
            speed: '0.5s',
            opacity: '0.6s',
            termination: 300,
        },
        autoRevert: true,
        location: 'top',
        inverse: false,
    },
};
