import api from '@/services/api';
import { store } from '@/store/store';

export default {
    data() {
        return {
            isSuccess: true,
        };
    },

    methods: {
        /**
         * Check transaction status on mounted
         */
        checkTransactionStatus() {
            this.$Progress.start();

            api.get(`/api/transaction-status?checkoutId=${this.$route.query.resourcePath}`)
                .then((response) => {
                    if (response.data.code === 'GET_TRANSACTION_STATUS_SUCCESS') {
                        this.isSuccess = true;
                        this.$toast.open(this.$i18n.t('messages.success'));
                        store.dispatch('setResources', response.data.body.user);
                    } else {
                        this.isSuccess = false;
                        this.$toast.error(this.$i18n.t('messages.error'));
                    }

                    this.$Progress.finish();
                }).catch(() => {
                    this.isSuccess = false;
                    this.$Progress.finish();
                    this.$toast.error(this.$i18n.t('messages.error'));
                });
        },
    },

    mounted() {
        this.checkTransactionStatus();
    },
};
