import BundleAccount from '@/components/Resources/Partials/BundleAccount.vue';
import Loyalty from '@/components/Resources/Partials/Loyalty.vue';
import TariffPlan from '@/components/Resources/Partials/TariffPlan.vue';
import api from '@/services/api';
import { mapGetters } from 'vuex';
import { store } from '@/store/store';

export default {
    data() {
        return {
            resources: {
                bundleAccount: [],
                loyalty: [],
                main: [],
                tariffPlan: [],
                other: [],
            },
            tariffName: '',
        };
    },

    components: {
        BundleAccount,
        Loyalty,
        TariffPlan,
    },

    computed: {
        ...mapGetters({
            locale: 'locale',
            contract: 'contract/contract',
        }),
    },

    methods: {
        /**
         * Get resources for chosen contract
         */
        getResources() {
            this.$Progress.start();
            api.get('/api/resources')
                .then((response) => {
                    this.resources.bundleAccount = response.data.body.resources.BUNDLE_ACCOUNT;
                    this.resources.loyalty = response.data.body.resources.LOYALTY;
                    this.resources.main = response.data.body.resources.MAIN;
                    this.resources.tariffPlan = response.data.body.resources.TARIFF_PLAN;
                    this.resources.other = response.data.body.resources.OTHER;
                    this.tariffName = response.data.body.tariff_name;
                    store.dispatch('setResources', response.data.body.user);
                    this.$Progress.finish();
                })
                .catch(() => {
                    this.$toast.error(this.$i18n.t('messages.error'));
                    this.$Progress.finish();
                });
        },
    },

    /**
     * Listen for object changed for clicked contract
     */
    watch: {
        'contract.clickedContract.phoneNumber': {
            handler() {
                this.getResources();
            },
            deep: true,
        },

        locale() {
            this.getResources();
        },
    },

    mounted() {
        if (this.contract.clickedContract.isMobile) {
            this.getResources();
        }
    },
};
