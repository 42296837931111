import actions from '@/store/actions/listingActions';
import * as types from '@/store/mutation-types';
import { format } from 'date-fns';

/**
 * Define the module state
 *
 * @type {object}
 */
const moduleState = {
    listings: [],
    modalActive: false,
    listingPdf: {},
    pagination: {},
    clickedListing: { // This object represent clicked listing for more details preview
        phoneNumber: '',
        price: '',
        callStart: '',
        roaming: '',
        unit: '',
        usedUnitsLabel: '', // 34.28 MB
        type: '', // Call/Data/Message
        usedUnits: '', // 35946721
    },
    monthNamesSerbia: ['Januar', 'Februar', 'Mart', 'April', 'Maj', 'Jun',
        'Jul', 'Avgust', 'Septembar', 'Oktobar', 'Novembar', 'Decembar',
    ],
    monthNamesGermany: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni',
        'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember',
    ],
};

const mutations = {
    /**
     * Set or update current listings
     * @param state
     * @param payload
     */
    [types.SET_ALL_LISTINGS](state, payload) {
        state.listings = payload.listing;
        state.pagination = payload.pagination;
        if (payload.listingPdf.length) {
            state.listingPdf = payload.listingPdf[0];
        } else {
            state.listingPdf = [];
        }
    },

    /**
     * Set parameters for clicked modal and activate modal to show on page
     * @param state
     * @param listing
     */
    [types.SET_CLICKED_MODAL_LISTING](state, listing) {
        state.clickedListing.usedUnits = listing.usedUnits;
        state.clickedListing.type = listing.type;
        state.clickedListing.unit = listing.unit;
        state.clickedListing.usedUnitsLabel = listing.usedUnitsLabel;
        state.clickedListing.phoneNumber = listing.phoneNumber;
        state.clickedListing.price = listing.price;
        state.clickedListing.callStart = listing.date;
        state.clickedListing.roaming = listing.roaming;
        state.modalActive = true;
    },

    /**
     * Close modal
     * @param state
     */
    [types.SET_CLOSE_MODAL_ON_CLICK](state) {
        state.modalActive = false;
    },
};

/**
 * Define the module getters
 *
 * @type {object}
 */
const getters = {
    listing: (state) => state,
    lastMonth: (state, getters, rootState) => {
        // Check selected language sr/de;
        let monthNames;
        if (rootState.selectedLocale === 'sr') {
            monthNames = state.monthNamesSerbia;
        } else {
            monthNames = state.monthNamesGermany;
        }

        const now = new Date();
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        const index = firstDayOfMonth.getMonth();
        const month = monthNames[index];
        const year = firstDayOfMonth.getFullYear();

        const monthNumber = ((index + 1) < 10 ? '0' + (index + 1) : (index + 1));
        const firstDay = `${year}-${monthNumber}-01`;
        const lastDay = `${year}-${monthNumber}-${format(new Date(year, index + 1, 0), 'd')}`;

        return {
            name: `${month} ${year}.`,
            firstDay,
            lastDay,
        };
    },
    lastSixMonths: (state, getters, rootState) => {
        // Check selected language sr/de;
        let monthNames;
        if (rootState.selectedLocale === 'sr') {
            monthNames = state.monthNamesSerbia;
        } else {
            monthNames = state.monthNamesGermany;
        }
        const lastSixMonths = [];
        const now = new Date();
        let firstDayOfMonth;
        let month;
        let year;
        let index;

        // last 6 months
        for (let i = 0; i < 6; i++) {
            firstDayOfMonth = new Date(now.getFullYear(), now.getMonth() - i, 1);
            index = firstDayOfMonth.getMonth();
            month = monthNames[index];
            year = firstDayOfMonth.getFullYear();
            const name = `${month} ${year}.`;
            const monthNumber = ((index + 1) < 10 ? '0' + (index + 1) : (index + 1));
            const firstDay = `${year}-${monthNumber}-01`;
            const lastDay = `${year}-${monthNumber}-${format(new Date(year, index + 1, 0), 'd')}`;

            lastSixMonths.push({
                name,
                firstDay,
                lastDay,
            });
        }
        return lastSixMonths;
    },
};

export default {
    actions,
    getters,
    mutations,
    namespaced: true,
    state: moduleState,
};
