export default {
    methods: {
        redirectAccepted() {
            this.$emit('redirectAccepted', true);
        },

        close() {
            this.$emit('close', true);
        },
    },
};
