<template>
    <section class="sc-dashboard">
        <div class="container flex-wrapper">

            <main class="main-content layout-calc">
                <div class="row">
                    <!-- DATA PACKAGES-->
                    <div class="col-md-6 col-xl-4 col-margin-bottom-20" v-for="tariff in tariffs" :key="tariff.productId">
                        <Package
                            :tariff="tariff"
                            :name="tariff.name"
                        />
                    </div>

                </div>
            </main>

        </div>
    </section>
</template>

<script>
import TvTariffs from './TvTariffs';
export default TvTariffs;
</script>
