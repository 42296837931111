import api from '@/services/api';

export default {
    data() {
        return {
            isSuccess: true,
        };
    },

    methods: {
        /**
         * Check transaction status on mounted
         */
        checkTransactionStatus() {
            this.$Progress.start();

            const params = new URLSearchParams();
            params.append('checkoutId', this.$route.query.resourcePath);

            api.post('/api/bill-status', params)
                .then((response) => {
                    if (response.data.code === 'GET_TRANSACTION_STATUS_SUCCESS') {
                        this.isSuccess = true;
                        this.$toast.open(this.$i18n.t('messages.success'));
                    } else {
                        this.isSuccess = false;
                        this.$toast.error(this.$i18n.t('messages.error'));
                    }

                    this.$Progress.finish();
                }).catch(() => {
                    this.isSuccess = false;
                    this.$Progress.finish();
                    this.$toast.error(this.$i18n.t('messages.error'));
                });
        },
    },

    mounted() {
        this.checkTransactionStatus();
    },
};
