<template>
    <div id="black-screen-loader" v-if="loader">
    </div>
</template>

<script>
export default {
    data() {
        return {
            loader: true,
        };
    },
};
</script>
