<template>
    <div id="recharge-voucher" class="container tab-pane fade"><br>
        <div class="form-group">
            <label for="inputNumber">{{ $t('pages.rechargeCredit.tab2.title1') }}</label>
            <input type="text"
                   class="form-control"
                   id="inputNumber"
                   v-model="phoneNumber"
            >
        </div>
        <div class="form-group">
            <label for="inputVoucher">{{ $t('pages.rechargeCredit.tab2.title2') }}</label>
            <input type="text"
                   class="form-control"
                   id="inputVoucher"
                   v-model="voucher"
            >
        </div>
        <div class="form-group">
            <button
                class="red-button"
                @click="submitVoucher"
            >
                {{ $t('pages.rechargeCredit.tab2.button') }}
            </button>
        </div>
    </div>
</template>

<script>
import Voucher from './Voucher';
export default Voucher;
</script>
