import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            listing: 'listing/listing',
        }),
    },

    methods: {
        close() {
            this.$emit('close');
        },
    },
};
