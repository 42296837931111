<template>
    <section class="sc-dashboard">
        <div class="container flex-wrapper">
            <main class="main-content layout-calc" id="listing-page">
                <h3 class="text-left">{{ $t('pages.listing.name') }}</h3>
                <hr>
                <div id="listing-wrapper">
                    <div class="form-group">
                        <label for="selectMonth">{{ $t('pages.listing.month') }}</label>
                        <select class="form-control"
                                id="selectMonth"
                                @change="onChangeMonth($event)"
                                v-model="lastMonth.name"
                        >
                            <option v-for="(month, index) in lastSixMonth"
                                    :key="index"
                                    :value="month.name"
                            >
                                {{ monthName(month.name) }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="selectType">{{ $t('pages.listing.services.name') }}</label>
                        <select class="form-control"
                                id="selectType"
                                @change="onChangeType($event)"
                                v-model="selectedType"
                        >
                            <option value="ALL">{{ $t('pages.listing.services.param1') }}</option>
                            <option value="CALL">{{ $t('pages.listing.services.param2') }}</option>
                            <option value="MESSAGE">{{ $t('pages.listing.services.param3') }}</option>
                            <option value="DATA">{{ $t('pages.listing.services.param4') }}</option>
                        </select>
                    </div>
                    <div class="form-group" v-if="Object.keys(listing.listingPdf).length">
                        <button
                            type="button"
                            class="btn btn-light"
                            id="download-pdf"
                            @click="downloadListingPdf(listing.listingPdf.noderef, listing.listingPdf.filename)"
                        >{{ $t('pages.listing.downloadLabel') }}</button>
                    </div>
                </div>
                <div id="list-details"
                     v-if="listing.listings.length"
                >
                        <ul>
                            <li v-for="(listing, index) in listing.listings" :key="index">
                            <span class="icon icon-new-poziv" v-if="listing.type === 'CALL'">
                                <span class="path1">
                                    <span class="path2"></span>
                                </span>
                            </span>
                                <span class="icon icon-new-mobilni-internet" v-else-if="listing.type === 'DATA'">
                                <span class="path1">
                                    <span class="path2"></span>
                                </span>
                            </span>
                                <span class="icon icon-email-red" v-else>
                                <span class="path1">
                                    <span class="path2"></span>
                                </span>
                            </span>
                                <div class="first-span">
                                    <h4 class="phone-number-left" v-if="listing.type === 'CALL'">{{ listing.phoneNumber }}</h4>
                                    <h4 class="phone-number-left" v-else-if="listing.type === 'DATA'">{{ $t('pages.listing.types.data') }}</h4>
                                    <h4 class="phone-number-left" v-else>SMS</h4>
                                    <small>{{ listing.price.label }} {{ listing.price.value }}</small>
                                </div>
                                <div class="second-span">
                                    <p class="listing-date">{{ listing.date | date }}</p>
                                    <p class="listing-details"
                                       @click="showModal(listing)"
                                    >
                                        {{ $t('pages.listing.buttons.details') }} <span class="icon-arrow-right"></span>
                                    </p>
                                </div>
                            </li>
                            <infinite-loading
                                :identifier="infiniteId"
                                @infinite="infiniteHandler"
                                v-if="listing.listings.length"
                            >
                                <div slot="spinner">
                                    {{ $t('pages.listing.infiniteLoading.messages.loading') }}
                                </div>
                                <div slot="no-more">
                                    {{ $t('pages.listing.infiniteLoading.messages.noMoreData') }}
                                </div>
                                <div slot="no-results">
                                    {{ $t('pages.listing.infiniteLoading.messages.noMoreData') }}
                                </div>
                            </infinite-loading>
                        </ul>
                </div>
                <div v-else>
                    <p class="no-listing">{{ $t('pages.listing.noListings') }}</p>
                </div>

                <!-- Modal -->
                <div v-if="listing.modalActive" id="listing-modal-wrapper">
                    <InternetModal v-if="listing.clickedListing.type === 'DATA'"
                                   @close="closeModal"
                    />
                    <PhoneModal v-else-if="listing.clickedListing.type === 'CALL'"
                                @close="closeModal"
                    />
                    <SmsModal  v-else
                               @close="closeModal"
                    />
                </div>
            </main>
        </div>
    </section>
</template>
<script>
import Listing from '@/components/Listing/Listing';
export default Listing;
</script>
