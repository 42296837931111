import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            username: '',
            password: '',
        };
    },

    computed: {
        ...mapGetters({
            user: 'user/user',
        }),
    },

    methods: {
        ...mapActions('user', ['loginViaPassword']),
    },

    mounted() {
        this.$refs.firstNameInput.focus();
    },
};
