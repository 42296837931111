import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters({
            contractModule: 'contract/contract',
        }),

        /**
         * This is needed to know what class to append on float box with contracts
         * @return {number}
         */
        screenWidth() {
            return window.innerWidth;
        },
    },

    methods: {
        ...mapActions('contract', ['setClickedContract']),
    },

    /**
     * This will close float box collapsed navigation
     */
    watch: {
        '$route.path': function () {
            if (this.screenWidth < 1024) {
                this.$refs.floatBoxMenu.classList.remove('show');
            }
        },
    },
};
