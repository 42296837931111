import Vue from 'vue';
import VueProgressBar from 'vue-progressbar';
import config from '@/config/config';
import VueToast from 'vue-toast-notification';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import router from './router';
import { store } from './store/store';
import i18n from './i18n';

// Progress bar-line on the top of screen
Vue.use(VueProgressBar, config.nProgressOptions);

Vue.use(VueToast);

Vue.use(VueI18n);

Vue.config.productionTip = false;

// Set some custom filters
require('./filters/filter.toDate');
require('./filters/filter.toHoursMinutesSeconds');
require('./filters/filter.toComma');
require('./filters/filter.contractTypeChangeLanguage');

new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
