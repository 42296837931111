<template>
    <div id="news-sidebar" :class="{'open-news-menu': isActiveNewsMenu}">
        <header>
            <h2 class="text-center">{{ $t('sideBar.title') }}</h2>
            <span @click="toggleNewsMenu">X</span>
        </header>
        <div :class="{'news-wrapper': true, 'active-news-wrapper': !singleNews.read}" v-for="(singleNews, index) in allNotifications" :key="index">
            <h5>{{ singleNews.title }}</h5>
            <p v-html="singleNews.shortDescription"></p>
            <router-link
                :to="{
                name: 'notification',
                params: {
                    id: singleNews.id, title:singleNews.title, text: singleNews.text,
                     }
                }"
                @click.native="toggleNewsMenu">
                {{ $t('sideBar.button') }}
            </router-link>
        </div>
    </div>
</template>

<script>
import NewsSideBar from './NewsSideBar';
export default NewsSideBar;
</script>
