import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            smsCode: '',
        };
    },

    computed: {
        ...mapGetters({
            user: 'user/user',
        }),
        realSmsCode() {
            // TODO PROVERITI DAL RADI OVO ISPOD
            if ('OTPCredential' in window) {
                navigator.credentials.get({
                    otp: { transport: ['sms'] },
                }).then((otp) => otp.code);
            }
        },
    },

    methods: {
        ...mapActions('user', ['loginViaSms']),
    },

    mounted() {
        this.$refs.smsLoginInput.focus();
    },
};
