import CreditCard from './CreditCard.vue';
import ExternalPaymentsCard from './ExternalPaymentsCard.vue';

export default {
    props: ['formActionUrl'],

    data() {
        return {
            creditCard: false,
        };
    },

    components: {
        CreditCard,
        ExternalPaymentsCard,
    },

    methods: {
        close() {
            this.$emit('close');
        },

        activeCreditCard() {
            this.creditCard = !this.creditCard;
        },
    },
};
