import api from '@/services/api';

export default {
    /**
     * When component is mounted, take all listings from server
     *
     * @param commit
     * @param payload
     * @param state
     */
    getAllListings({ commit }, payload = false) {
        let params = '';
        // If selected month or type is available
        if (payload) {
            // If type is selected to all, then just ignore that parameter
            if (payload.type === 'ALL') {
                params = `startDate=${payload.startDate}&endDate=${payload.endDate}`;
            } else {
                params = `type=${payload.type}&startDate=${payload.startDate}&endDate=${payload.endDate}`;
            }
        }

        api.get(`/api/listing?${params}`)
            .then((response) => {
                // Show listings
                commit('SET_ALL_LISTINGS', {
                    listing: response.data.body.listing,
                    listingPdf: response.data.body.listingPdf,
                    pagination: response.data.body.pagination,
                });
            });
    },

    /**
     * Set modal properties and show on page
     *
     * @param commit
     * @param listing
     */
    showModal({ commit }, listing) {
        commit('SET_CLICKED_MODAL_LISTING', listing);
    },

    /**
     * Close modal
     *
     * @param commit
     * @param listing
     */
    closeModal({ commit }) {
        commit('SET_CLOSE_MODAL_ON_CLICK');
    },
};
