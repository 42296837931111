export default {
    props: ['formActionUrl', 'active'],

    computed: {
        cssProps() {
            return {
                '--display': this.active ? 'block' : 'none',
            }
        }
    }
}
