<template>
    <section class="sc-dashboard">
        <div class="container flex-wrapper">

            <main class="main-content layout-calc">
                <!-- Nav tabs -->
                <ul class="nav nav-tabs">
                    <li class="nav-item">
                        <a class="nav-link tab-link active" data-toggle="tab" href="#change-language-tab">
                            {{ $t('pages.administration.tab1.name') }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link tab-link" data-toggle="tab" href="#sms-notification-tab">
                            {{ $t('pages.administration.tab2.name') }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link tab-link" data-toggle="tab" href="#voice-mail-tab">
                            {{ $t('pages.administration.tab3.name') }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link tab-link" data-toggle="tab" href="#gdpr-tab">
                            {{ $t('pages.administration.tab4.name') }}
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link tab-link" data-toggle="tab" href="#user-settings-tab">
                            {{ $t('pages.administration.tab5.name') }}
                        </a>
                    </li>
                </ul>

                <!-- Tab panes -->
                <div class="tab-content">
                    <ChangeLanguage />
                    <SmsNotifications />
                    <VoiceMail />
                    <Gdpr />
                    <UserSettings />
                </div>
            </main>

        </div>
    </section>
</template>

<script>
import Administration from './Administration';
export default Administration;
</script>
