<template>
    <div class="package-item">
        <h3 class="package-title">{{ name }}</h3>
        <ul v-if="Object.keys(tariff).length">
            <li class="package-info"><p><span class="color-red">{{ tariff.exYuChannels }}</span> {{ $t('pages.tv.exCanal') }}</p></li>
            <li class="package-info"><p><span class="color-red">{{ tariff.hdChannels }}</span> {{ $t('pages.tv.hdCanal') }}</p></li>
            <li class="package-info"><p><span class="color-red">{{ tariff.channelsForAdults }}</span> {{ $t('pages.tv.adultCanal') }}</p></li>
            <li class="package-info"><p><span class="color-red">{{ tariff.radioStations }}</span> {{ $t('pages.tv.radio') }}</p></li>
            <li class="package-info"><p>
                <span v-if="tariff.videoClubSeriesAndMovies" class="color-red icon icon-check"></span>
                <span v-else class="color-red">X</span>
                {{ $t('pages.tv.videoClub') }}</p>
            </li>
            <li class="package-info"><p>
                <span v-if="tariff.watchBackTo7Days" class="color-red icon icon-check"></span>
                <span v-else class="color-red">X</span>
                {{ $t('pages.tv.watchBack') }}
            </p></li>
            <li class="package-info"><p><span class="color-red">{{ tariff.maxNumberSofDevices }}</span> {{ $t('pages.tv.maxDevices') }}</p></li>
            <li class="package-info"><p><span class="color-red">{{ tariff.simultaneousWatching }}</span> {{ $t('pages.tv.onSameDeviceCount') }}</p></li>
        </ul>
    </div>
</template>

<script>
import Package from './Package';
export default Package;
</script>
