<template>
    <div id="recharge-credit" class="container tab-pane active"><br>
        <div class="form-group">
            <label for="exampleInputEmail1">{{ $t('pages.rechargeCredit.tab1.title1') }}</label>
            <input type="text"
                   class="form-control"
                   id="exampleInputEmail1"
                   placeholder="436XXXXXXXX"
                   inputmode="numeric"
                   v-model="phone"
            >
        </div>
        <div class="form-group">
            <label for="credit-amount">{{ $t('pages.rechargeCredit.tab1.title2') }}</label>
            <input type="number"
                   class="form-control"
                   id="credit-amount"
                   inputmode="numeric"
                   min="10"
                   max="50"
                   v-model="rechargeCreditAmount"
            >
            <small :class="{'form-text text-muted': true, 'color-red': rechargeCreditAmountError}">
                {{ $t('pages.rechargeCredit.tab1.description') }}
            </small>
        </div>
        <div class="form-group">
            <button class="red-button"
                    @click="openConfirmationModal"
            >
                {{ $t('pages.rechargeCredit.tab1.button') }}
            </button>
        </div>

        <ConfirmationModal
            v-if="isCreditButtonClicked"
            @close="closeConfirmationModal"
            @paymentAgree="sendAmountAndOpenModalForm"
        />

        <PaymentModal
            v-if="showPaymentModal"
            @close="closeModal"
            :formActionUrl="formActionUrl"
        />

        <div ref="paymentScriptWrapper"></div>
    </div>
</template>

<script>
import Credit from './Credit';
export default Credit;
</script>
