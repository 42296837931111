import FloatBox from '@/components/Partials/FloatBox/FloatBox.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
    data() {
        return {
            singleNotification: {
                title: '',
                text: '',
                read: true,
            },
        };
    },
    components: {
        FloatBox,
    },
    computed: {
        ...mapGetters({
            notification: 'notification/notification',
        }),
    },
    methods: {
        ...mapActions('notification', ['readNotificationAndGetAllNotificationsAndSetCurrentNotification']),
    },

    watch: {
        '$route.path': function () {
            this.readNotificationAndGetAllNotificationsAndSetCurrentNotification(this.$route.params.id);
        },
    },

    mounted() {
        this.readNotificationAndGetAllNotificationsAndSetCurrentNotification(this.$route.params.id);
    },
};
