<template>
    <div :style="cssProps">
        <form :action="formActionUrl"
              class="paymentWidgets"
              data-brands="VISA MASTER"
        ></form>
    </div>
</template>

<script>
import CreditCard from './CreditCard';
export default CreditCard;
</script>

<style>
div.wpwl-container-card {
    display: var(--display) !important;
}
</style>
