<template>
    <div>
        <!-- Modal -->
        <div id="payment-modal">
            <div id="payment-modal-wrapper">
                <div class="modal fade show"
                     tabindex="-1" role="dialog"
                     aria-labelledby="exampleModalCenterTitle"
                     aria-hidden="true"
                     style="display: block"
                >
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
                                    <span class="close-span" aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <ul class="nav nav-tabs ul-payment">
                                    <li class="nav-item nav-payment" @click.prevent="creditCard = false">
                                        <a :class="{'nav-link tab-link': true, 'active': !creditCard}">
                                            {{ $t('paypalSofort') }}
                                        </a>
                                    </li>
                                    <li class="nav-item nav-payment" @click.prevent="creditCard = true">
                                        <a :class="{'nav-link tab-link': true, 'active': creditCard}">
                                            {{ $t('creditCard') }}
                                        </a>
                                    </li>
                                </ul>
                                <CreditCard
                                    :formActionUrl="formActionUrl"
                                    :active="creditCard"
                                ></CreditCard>
                                <ExternalPaymentsCard
                                    :formActionUrl="formActionUrl"
                                    :active="!creditCard"
                                ></ExternalPaymentsCard>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PaymentModal from './PaymentModal';
export default PaymentModal;
</script>
