<template>
    <!-- Modal -->
    <div id="logout-modal">
        <div id="listing-modal-wrapper">
            <div id="logout-modal-wrapper">
                <div class="modal fade show"
                     tabindex="-1" role="dialog"
                     aria-labelledby="exampleModalCenterTitle"
                     aria-hidden="true"
                     style="display: block"
                >
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4>{{ $t('gdprModal.title') }}</h4>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="close">
                                    <span class="close-span" aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div class="form-group"
                                     v-for="(gdpr, index) in gdprs"
                                     :key="index"
                                >
                                    <GdprInput :index="index" :gdpr="gdpr"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Gdpr from './Gdpr';
export default Gdpr;
</script>

<style lang="scss" scoped>
.modal-body {
    flex-direction: column;
    div {
        margin: 10px auto;
    }
}
</style>
