import Vue from 'vue';
import { format } from 'date-fns';

Vue.filter('hoursMinutesSeconds', (value) => {
    if (!value) {
        return '';
    }

    return format(new Date(value), 'HH:mm:ss');
});
